/*==testimonial*/

section.testimonials {

  .outer-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .owl-theme {
      .owl-nav {
        display: none;

      }
      .owl-stage {
        display: inline-flex;
      }
      .owl-dots {
        margin-top: 0;

        text-align: left;
        .owl-dot {
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          padding: 6px;
          background-color: transparent;
          border: 1px solid rgba(0, 0, 0, 0);
          margin-right: 3px;
          span {
            height: 6px;
            width: 6px;
            padding: 0;
            margin: 0;
            color: $color_light_black;
          }
          &.active, &:hover {
            border: 1px solid $color_light_black;
          }
          &:focus {
            outline-style: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }
      }

    }

  }
  .testimonial-wrapper {
    //overflow: hidden;
    .content-wrapper {
      position: relative;
    }
    .description {
      //color: $color_white;
      margin-bottom: 30px;
      display: block;
      text-transform: none;
    }
    .by {
      //font-size: 1;
      //color: $color_white;
      font-weight: normal;
      display: block;
      text-align: right;
    }
    .owl-item, .cloned {
      opacity: 0;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

    }
    .owl-item.active {
      opacity: 1;

    }
  }

  .image-placeholder {
    width: 100%;
    padding-top: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    .inner-wrapper {
      border: none;
    }
    img {
      object-fit: cover;
      opacity: 0;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -ms-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
      min-height: 100%;
      min-width: 100%;
      &.active {
        opacity: 1;
      }
    }
  }

  .owl-item.active {
    .image-placeholder img {
      opacity: 1;
    }
  }

  @media screen and (max-width: $media_sm) {
    text-align: center;
    .col-md-7 {
      padding: 0;
      .heading-container, .testimonial-wrapper {
        padding: 0 15px;

      }
      .heading-container .size-3 {
        margin-bottom: 10px;
      }
    }

    .testimonial-wrapper {
      text-align: center;
      .content-wrapper {
        padding: 0 !important;
        .description {
          font-weight: $fw_medium;
          margin-bottom: $padding_content_small;
          font-size: 4.8vmin;
        }
        .by {
          text-align: center;
        }
      }
    }

    .outer-wrapper .owl-theme .owl-dots {
      margin-top: 40px;
      transform: none;
      text-align: center;
    }
    .image-placeholder {
      overflow: hidden;
      //max-width: 50%;
      //padding-top: 50%;
      margin: 0 auto 15px;
    }
  }

  /*if centered*/
  &.text-center {
    .col-md-3 {
      display: none;
    }
    .outer-wrapper {

      .testimonial-wrapper .content-wrapper {
        .by {
          text-align: center;
        }
      }

      .owl-theme {

        .owl-dots {

          text-align: center;
          position: relative;
          margin-top: $padding_content;
          transform: translateY(-100%);
          display: none !important;
        }
      }
    }
    #testimonial-nav-container {
      &.owl-nav {
        display: flex !important;
        justify-content: space-between;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        width: 100%;
        left: 0;

        button {
          background-color: transparent;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }

    @media screen and (max-width: $media_sm) {
       .outer-wrapper .owl-theme .owl-dots{
        display: block !important;
      }
      #testimonial-nav-container.owl-nav {
        display: none !important;
      }
    }

  }
}

section.testimonials.no-image {
  .image-placeholder {
    display: none;
  }
}

section.testimonials:not(.text-center) {
  .item {
    .image-placeholder {
      display: none;
    }
  }
}
