section.banner {
  img.pattern {
    position: absolute;
    z-index: 9;
    left: $padding_content;
    top: $padding_content;
    &.bottom {
      top: auto;
      bottom: $padding_content;
    }
    &.right {
      left: auto;
      right: $padding_content;
    }
    @media screen and (max-width: $media_sm_) {
      max-width: 40%;
    }
  }
  &:not(.right-aligned) {
    min-height: 600px;
  }
  &.background-contain {
    min-height:300px;
    justify-content: center !important;
    .size-big{
      margin: 4vw 0 !important;
    }
  }

  //max-height: 725px;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  &.Contact-banner {
    background: none !important;
  }

  &.overlay:before {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color_black;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.6;
    z-index: 0;
  }

  &.has-carousel {
    padding-bottom: 13em;
  }

  .banner-logo {
    //position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    padding-top: 29.43%;
    //height: calc(100% - 109px);
    max-height: 410px;
    max-width: 410px;
    min-height: 225px;
  }

  .banner-title-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  .banner-title {
    position: absolute;
    bottom: 150px;
    font-weight: bold;
    font-size: 3.75em;
    color: #fff;
    line-height: 50px;
    text-align: right;
    text-transform: uppercase;
  }
  .size-2 {
    margin-top: 25px;
  }
  .btn {
    margin-top: 1.875em;
    margin-bottom: 10px;
  }


  .banner-content{
    .size-big{
      >span {
        visibility: hidden;
        display: block;
        padding-bottom: 20px;
        margin-top: -20px;
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: $media_sm) {
    min-height: 450px;
    .signup-box {
      padding: 10px 15px;
    }
    &.banner {
      .arrow-container.prev, .arrow-container.next, .text-right {
        display: none;
      }
    }
  }
}




section.banner.vimeo-wrapper {
  position: relative;
  top: 0;
  left: 0;
  //width: 100vw;
  pointer-events: none;
  overflow: hidden;
  /*background-color: #f00;*/
  overflow: hidden;
  float: left;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  a {
    pointer-events: auto;
  }

  iframe, video {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100%;
    min-width: 286vw; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  @media screen and (max-width: $media_sm_) {
    iframe, video {
      min-width: 200vmax;
    }
  }
}

/*for left -right banner section*/
section.banner.left-aligned, section.banner.right-aligned {
  background: none !important;
  padding: 0px 0 0;
  &:before {
    display: none;
  }
  .banner-image {
    padding-top: 70%;
    margin-left: -15px;
    margin-right: -15px;
    width: 100vw;
    overflow: hidden;
    position: relative;
  }

  @media screen and (min-width: $media_lg) {
    .container-fluid {
      padding: 0;
      .banner-content {
        padding-left: 15px;
      }
    }

  }

  @media screen and (min-width: $media_sm_) {
    .banner-image {
      padding-top: 0%;
      width: 50vw;
    }

  }
}

section.banner.left-aligned {
  @media screen and (min-width: $media_sm_) {
    &:not(.tiled) {
      .col-md-6 {
        padding-left: 0;
      }
    }
  }
}

section.banner.right-aligned {

  @media screen and (min-width: $media_sm_) {

    .col-md-6 {
      order: 1;

    }
    .col-md-6:first-child {
      order: 2;
    }
    &:not(.tiled) {
      .col-md-6:first-child {
        padding-right: 0;
      }
    }
  }
}

/*if tiled it will be container width*/
section.tiled {
  .container-fluid {
    max-width: 1160px;
    .banner-image {
      width: 100%;
      height: auto;
      padding-top: 123%;
      margin: 0 auto;
    }

  }
  @media screen and (min-width: $media_xl) {
    .container-fluid {
      padding: 0;
    }
  }
}

/*==text-center*/
section.banner.text-center {
  .container, .container-fluid {
    text-align: center;
    .row {
      justify-content: center;
    }
  }
}

/*
*disableoverlay
*/
section.banner.disable-overlay {
  &:before {
    display: none;
  }
}

body.loaded {
  section.banner{
    .banner-content{
      .size-big{
        @for $i from 0 through 10 {
          > span:nth-child(#{$i}) {
            animation: ClipRectIn 1.5s cubic-bezier(.47, .28, .01, 1.05) 500ms + ($i * 250ms) forwards;
          }
        }
      }
    }
  }
}
