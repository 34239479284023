header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  //z-index: 2147483641;
  z-index: 99999;
  display: block;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: background 0.25s, transform 0.3s;
  transition: background 0.25s, transform 0.3s;

  &.enable-shadow {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
  }

  &.nav-capital {
    nav a {
      text-transform: uppercase;
    }
  }

  .signup-box {
    //background-color: $color_black;
    position: relative;
  }

  .navbar-brand img {
    max-width: 365px;
    //max-height: 100px;
  }

  nav {
    //min-height: 120px;
    &.navbar {
      padding: 0;
    }

    .navbar-nav {
      align-items: center;
      //min-height: 40px;
    }

    li.dropdown:hover .dropdown-menu {
      display: block;
      //top: 20px;
      top: 12px;
    }

    .dropdown-toggle::after {
      position: absolute;
      right: 0;
      top: 38%;
      font-size: 12px;
    }

    .nav-item.dropdown {
      margin-right: 0.5rem;
    }

    span.dropdown-toggle {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 999;
      cursor: pointer;
      /*color: $color_light_black;
        &:hover {
          color: $color_white;
        }*/
    }

    .dropdown-menu {
      background-color: transparent;
      padding: 20px 0 0;
      border: none;

      .child-container {
        background-color: $color_white;
        border: 1px solid rgba(0, 0, 0, .15);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 0;

        a:first-child {
          position: relative;
          // &:before {
          //   content: "";
          //   position: absolute;
          //   color: $color_white;
          //   left: 20%;
          //   top: -8px;
          //   width: 15px;
          //   height: 15px;
          //   background-color: #fff;
          //   border-top: 1px solid rgba(0, 0, 0, 0.15);
          //   border-left: 1px solid rgba(0, 0, 0, 0.15);
          //   transform: rotate(45deg);
          //   -webkit-transition: background-color 0s ease;
          //   -moz-transition: background-color 0s ease;
          //   -ms-transition: background-color 0s ease;
          //   -o-transition: background-color 0s ease;
          //   transition: background-color 0s ease;
          // }
          &:hover {
            &:before {
              //background: $color_blue_light;
            }
          }
        }
      }

      a {
        padding: 10px 20px;
        //font-weight: $fw_medium;
        //color: $color_medium_black;
        font-weight: unset;
        margin: 0 !important;
        width: auto;

        &:hover {
          //background-color: $color_blue_light;
          color: $color_white !important;
        }

        &.active {
          //color: $color_black;
          text-decoration: none;
          background-color: transparent;

          &:hover {
            //background-color: $color_blue_light;
            color: $color_white !important;
          }
        }
      }
    }

    .top {
      outline: none;
      border: none;
    }

    .left-nav {
      margin-right: 15px;
      margin-right: 0;
      padding-right: 0;
    }
  }

  nav a {
    text-decoration: none;
    //color: $color_white;
    //text-transform: uppercase;
    //font-weight: $fw_medium;
    //font-size: 0.875em;
    margin: 0 1rem;

    &.nav-link {
      margin-bottom: -10px;
      padding: 0 0 8px !important;
      // border-bottom: 2px solid rgba(0, 0, 0, 0);
    }

    &:hover {
      //color: $color_white;
    }

    &.btn-primary {
      min-width: 100px;
      //padding: 15px 20px;
    }
  }

  nav a.navbar-brand {
    padding: 30px 0 !important;
  }

  nav .active > a,
  nav a:hover {
    //border-bottom-color: $color_white;
    &.btn {
      // border-bottom-color: transparent;
    }
  }

  &.no-menu {
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }

    .collapse:not(.show),
    .navbar-nav {
      display: block;
    }
  }

  .navbar-toggler {
    outline: none;

    &:active,
    &:focus,
    &:hover {
      outline: none;
    }

    .material-icons {
      font-size: 45px;
    }
  }
  @media scr and (max-width: $media_lg) {
    .navbar {
      padding: 0.5rem 0;

      .navbar-brand {
        margin-right: 0;
      }
    }

    .navbar-toggler {
      outline-style: none;
      padding-right: 0;

      .material-icons {
        font-size: 45px;
        //color: $color_white;
      }
    }

    nav {
      padding-top: 0 !important;

      &:before {
        height: 65px;
      }

      a.nav-link {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }

      .dropdown-toggle::after {
        right: 0;
      }
    }
  }
  @media screen and (max-width: $media_md) {
    .navbar-nav {
      display: none;
    }

    nav {
      .right-section {
        overflow: hidden;
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .navbar-brand {
      //position: absolute;
      //top: 0px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    &.no-menu {
      .collapse:not(.show),
      .navbar-nav {
        margin-top: 0 !important;
      }
    }

    .logo-container{
      .navbar-brand{
        max-width: 45%;
        img{
          max-width: 100%;
        }
      }
      .right{
        max-width: 55%;
      }

      .btn-primary{
        min-width: auto;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

  }
  @media screen and (max-width: $media_sm) {
    .dropdown-toggle {
      right: 15px;
    }

    nav {
      //min-height: 100px;
      padding: 0 !important;
    }
  }
}

header.sticky {
  width: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  -ms-transform: translateY(-142px);
  transform: translateY(-142px);
  //background-color: #fff;
  transition: background 0.25s, transform 0.3s;

  nav {
    margin-top: 0;

    .primary {
      display: block;
    }

    .dropdown-toggle,
    a {
      text-decoration: none;
    }

    .nav-item.active > a {
      text-decoration: none;
    }
  }

  &.is-active {
    -webkit-box-shadow: 0 0 20px 0 rgba(200, 200, 200, 0.1);
    -moz-box-shadow: 0 0 20px 0 rgba(200, 200, 200, 0.1);
    box-shadow: 0 0 20px 0 rgba(200, 200, 200, 0.1);
    opacity: 1;
  }

  &.has-signup {
    -ms-transform: translateY(-202px);
    transform: translateY(-202px);
  }

  .dropdown-menu {
    &:before {
      display: none;
    }
  }
  @media screen and (max-width: $media_xs) {
    transform: translateY(-100px);

    &.has-signup {
      -ms-transform: translateY(-105px);
      transform: translateY(-105px);
    }
  }
}

body.ie {
  header,
  header.sticky {
    -ms-transform: translateY(-0px);
    transform: translateY(-0px);
  }
}

header.sticky.animate-nav {
  transition: background 0.25s, transform 0s !important;
}

body.header-sticky {
  header.is-active {
    position: fixed;
    //background-color: #fff;
    width: 100%;
    top: 0;
    transform: translateY(0%);
    opacity: 1;
    transition: background 0.25s, transform 0.3s;

    &.has-signup {
      transform: translateY(0%);
    }
  }
}

body.header-fixed {
  header {
    position: fixed;
  }
}
@media screen and (max-width: $media_lg) {
  .navbar-collapse,
  header nav {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  ul.navbar-nav {
    text-align: left;
  }
}
/*==nav align center*/

header.nav-align-center {
  @media screen and (min-width: $media_md) {
    nav {
      .right-section {
        width: 100%;

        .left-nav {
          display: flex;
          width: 100%;
        }
      }

      .navbar-nav {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.div-for-mobile {
  pointer-events: none;
  background-color: #fff;
  width: 100%;
  position: fixed;
  opacity: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //z-index: 2147483640;
  z-index: 9999;
  padding-top: calc(100px + 10vmin);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;

  &.has-signup {
    top: 65px;
  }

  &.nav-capital {
    ul li a {
      text-transform: uppercase;
    }
  }

  .btn-sale {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    //border: 15px solid $color_bg_gray;
    z-index: 9999;

    .btn-primary {
      font-size: 20px;
      font-weight: bold;
      height: 60px;
      display: flex !important;
    }
  }

  .top-bar {
    left: 100%;
    width: 100%;
    position: absolute;
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    ul {
      height: calc(100vh - 120px);
      display: block;
    }
  }

  ul {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 115px;
    list-style: none;
    padding-left: 0;
    margin: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;

    li {
      padding: 2vmin 20px;
      display: block;

      a {
        //color: $color_medium_black;
        text-decoration: none;
        font-size: 6vmin;
        font-weight: $fw_medium;
        line-height: 0.78;
        padding-bottom: 5px;
        position: relative;

        &.back {
          font-weight: normal;

          .material-icons {
            //color: $color_medium_black;
            //transform: rotate(180deg);
            position: relative;
            top: 5px;
          }
        }
      }

      &:last-child {
        border: none;
      }

      .child-arrow {
        position: absolute;
        right: -35px;
        top: 50%;
        //color: $color_medium_black;
        cursor: pointer;
        font-size: 6vmin;
        transform: translateY(-50%);
      }

      &.active {
        > a {
          //border-bottom: 2px solid $color_medium_black;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
        }
      }

      .dropdown-menu {
        position: relative;
        //display: block;
        border: none;
        width: 100%;
        padding-bottom: 30px;

        ul {
          padding-bottom: 0;
          overflow: hidden;

          li {
            padding: 20px 15px 0 20px;

            .dropdown-item {
              font-size: 16px;

              &:hover {
                background: none;
              }

              &.active {
                background: transparent !important;
                //color: $color_medium_black;
                display: initial;
                //border-bottom: 1px solid $color_medium_black;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: $media_lg) {
    display: none;
  }
  @media screen and (orientation: landscape) and (max-width: $media_sm) {
    padding-top: 100px;
  }
  @media screen and (orientation: portrait) {
    padding-top: 100px;

    > ul {
      padding-top: 25px;

      li {
        padding: 16px 15px 16px 20px;
      }
    }
  }
}
@media screen and (max-width: $media_md) {
  body .body-overlay {
    content: '';
    position: fixed !important;
    height: 100vh !important;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0;
  }

  body.nav-mobile {
    overflow: hidden !important;

    .body-overlay {
      height: 100vh;
      overflow: hidden;
      opacity: 1;
      z-index: 999;
    }

    header {
      .navbar-toggler {
        padding-right: 20px;

        img {
          display: none !important;
        }

        .material-icons {
          display: none;
        }

        .material-icons.d-none {
          display: block !important;
          margin-right: -10px;
          margin-top: -5px;
        }
      }

      nav {
        .right-section {
          //overflow: hidden;
          .top {
            transform: translateX(0);
          }
        }
      }
    }

    .div-for-mobile {
      pointer-events: auto;
      transform: translatex(0%);
      opacity: 1;

      .top-bar {
        .dropdown-item {
          padding: 0;

          &.active,
          &:active,
          &:hover {
            background-color: transparent;
            //color: $color_black;
          }
        }
      }
    }

    &.Home {
      header .navbar-toggler .material-icons.d-none {
        //color: $color_medium_black;
      }
    }
  }
}
@media screen and (max-width: $media_sm) {
  body.nav-mobile {
    header {
      .navbar-toggler {
        margin-left: 0;

        .material-icons {
          padding-top: 10px;
        }
      }
    }
  }
}
/*if tarnsparent nav*/

body.header-transparent {
  section.banner.full-height,
  section.banner.left-aligned .banner-image,
  section.banner.right-aligned .banner-image {
    height: 100vh;
  }
}
/*
*==scrolled
display secondary on scroll, for transparent nav for inner page display default secondary
*/

body.header-transparent.no-banner,
header.scrolled {
  .navbar-brand img.primary {
    display: none;
  }

  .navbar-brand img.secondary {
    display: flex !important;
  }
}
