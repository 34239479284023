@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Medium.eot');
    src: url('Calibre-Medium.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Medium.woff2') format('woff2'),
    url('Calibre-Medium.woff') format('woff'),
    url('Calibre-Medium.ttf') format('truetype'),
    url('Calibre-Medium.svg#Calibre-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Semibold.eot');
    src: url('Calibre-Semibold.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Semibold.woff2') format('woff2'),
    url('Calibre-Semibold.woff') format('woff'),
    url('Calibre-Semibold.ttf') format('truetype'),
    url('Calibre-Semibold.svg#Calibre-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Bold.eot');
    src: url('Calibre-Bold.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Bold.woff2') format('woff2'),
    url('Calibre-Bold.woff') format('woff'),
    url('Calibre-Bold.ttf') format('truetype'),
    url('Calibre-Bold.svg#Calibre-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-BlackItalic.eot');
    src: url('Calibre-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-BlackItalic.woff2') format('woff2'),
    url('Calibre-BlackItalic.woff') format('woff'),
    url('Calibre-BlackItalic.ttf') format('truetype'),
    url('Calibre-BlackItalic.svg#Calibre-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-ThinItalic.eot');
    src: url('Calibre-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-ThinItalic.woff2') format('woff2'),
    url('Calibre-ThinItalic.woff') format('woff'),
    url('Calibre-ThinItalic.ttf') format('truetype'),
    url('Calibre-ThinItalic.svg#Calibre-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-SemiboldItalic.eot');
    src: url('Calibre-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-SemiboldItalic.woff2') format('woff2'),
    url('Calibre-SemiboldItalic.woff') format('woff'),
    url('Calibre-SemiboldItalic.ttf') format('truetype'),
    url('Calibre-SemiboldItalic.svg#Calibre-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Black.eot');
    src: url('Calibre-Black.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Black.woff2') format('woff2'),
    url('Calibre-Black.woff') format('woff'),
    url('Calibre-Black.ttf') format('truetype'),
    url('Calibre-Black.svg#Calibre-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Thin.eot');
    src: url('Calibre-Thin.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Thin.woff2') format('woff2'),
    url('Calibre-Thin.woff') format('woff'),
    url('Calibre-Thin.ttf') format('truetype'),
    url('Calibre-Thin.svg#Calibre-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-MediumItalic.eot');
    src: url('Calibre-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-MediumItalic.woff2') format('woff2'),
    url('Calibre-MediumItalic.woff') format('woff'),
    url('Calibre-MediumItalic.ttf') format('truetype'),
    url('Calibre-MediumItalic.svg#Calibre-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-BoldItalic.eot');
    src: url('Calibre-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-BoldItalic.woff2') format('woff2'),
    url('Calibre-BoldItalic.woff') format('woff'),
    url('Calibre-BoldItalic.ttf') format('truetype'),
    url('Calibre-BoldItalic.svg#Calibre-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-LightItalic.eot');
    src: url('Calibre-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-LightItalic.woff2') format('woff2'),
    url('Calibre-LightItalic.woff') format('woff'),
    url('Calibre-LightItalic.ttf') format('truetype'),
    url('Calibre-LightItalic.svg#Calibre-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Regular.eot');
    src: url('Calibre-Regular.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Regular.woff2') format('woff2'),
    url('Calibre-Regular.woff') format('woff'),
    url('Calibre-Regular.ttf') format('truetype'),
    url('Calibre-Regular.svg#Calibre-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-RegularItalic.eot');
    src: url('Calibre-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('Calibre-RegularItalic.woff2') format('woff2'),
    url('Calibre-RegularItalic.woff') format('woff'),
    url('Calibre-RegularItalic.ttf') format('truetype'),
    url('Calibre-RegularItalic.svg#Calibre-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Light.eot');
    src: url('Calibre-Light.eot?#iefix') format('embedded-opentype'),
    url('Calibre-Light.woff2') format('woff2'),
    url('Calibre-Light.woff') format('woff'),
    url('Calibre-Light.ttf') format('truetype'),
    url('Calibre-Light.svg#Calibre-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GalanoGrotesque-Medium';
    src: url('GalanoGrotesque-Medium.otf') format("opentype") ;
    font-style: normal;
}

@font-face {
    font-family: 'GalanoGrotesqueAlt-SemiBold';
    src: url('GalanoGrotesqueAlt-SemiBold.otf')  format("opentype");
    font-style: normal;
}

@font-face {
    font-family: 'galano_grotesque_demobold';
    src: url('galano_grotesque_demo_bold-webfont.woff2') format('woff2'),
         url('galano_grotesque_demo_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'galano_grotesque_altlight';
    src: url('rene_bieder_-_galano_grotesque_alt_light-webfont.woff2') format('woff2'),
         url('rene_bieder_-_galano_grotesque_alt_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'galano_grotesque_altregular';
    src: url('rene_bieder_-_galano_grotesque_alt-webfont.woff2') format('woff2'),
         url('rene_bieder_-_galano_grotesque_alt-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
