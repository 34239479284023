.side-image-section {
  .image-block {
    overflow: hidden;
    z-index: 10;
    position: relative;

    img {
      object-fit: contain;
      width: 100%;
      max-width: 600px;
      height: 100%;
    }
  }

  h2 {
    margin-bottom: 20px;
    z-index: 10;
    position: relative;
  }
  @media screen and (min-width: $media_sm) {
    .inner-wrapper {
      padding-left: 3em;
    }

    &.Right,
    .right {
      .block:nth-child(1) {
        -ms-flex-order: 2;
        order: 2;
      }

      .inner-wrapper {
        padding-left: 0;
      }
    }

    .inner-wrapper.Right,
    .inner-wrapper.right {
      padding-right: 60px;
    }
  }

  form {
    margin-top: 30px;

    .row {
      margin-bottom: 15px;

      .form-field {
        &:first-child {
          padding-right: 0;
        }

        input {
          width: 100%;
          border-radius: 0;
          color: #212721!important;
          font-size: 14px;
          height: auto;
          line-height: 24px;
          margin-bottom: 0;
          outline-style: none;
          padding: 13px 20px;
          border: 1.5px solid #9b9b9b;
        }
      }
    }

    button {
      line-height: 1.14;
      font-weight: 500;
      font-style: normal;
      font-size: 14px !important;
      color: #ffffff;
      padding: 14px 30px;
      height: 50px;
      min-width: 110px;
    }
  }
  @media screen and (max-width: $media_md) {
    .image-block {
      margin-bottom: 20px;

      img {
        max-width: unset;
      }
    }

    form {
      .row {
        .form-field {
          &:first-child {
            padding-right: 15px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

._inline-form {
  padding: 0 !important;

  ._form-label {
    display: none !important;
  }

  input[type="text"],
  input[type="date"],
  textarea {
    width: 100% !important;
    border-radius: 0 !important;
    color: #212721 !important;
    font-size: 14px !important;
    height: auto !important;
    line-height: 24px !important;
    margin-bottom: 0 !important;
    outline-style: none !important;
    padding: 13px 20px !important;
    border: 1.5px solid #9b9b9b !important;
    font-family: semplicitapro,sans-serif !important;
  }

  button#_form_1_submit {
    line-height: 1.14 !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: #ffffff !important;
    padding: 14px 30px !important;
    height: 50px !important;
    min-width: 110px !important;
    background: #189ab4 !important;
    border-radius: 0 !important;
    font-family: semplicitapro,sans-serif !important;
    text-transform: none !important;
  }
}

._inline-form ._form_element {
  max-width: 225px !important;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 10px;
}

._inline-form ._form_element:nth-child(3) {
  max-width: 205px !important;
}

._inline-form ._form_element:nth-child(4) {
  max-width: 245px !important;
}

._inline-form ._button-wrapper {
  margin: 10px 0 0 0 !important;
}
@media screen and (max-width: 1120px) {
  ._inline-form ._form_element {
    max-width: 205px !important;
  }

  ._inline-form ._form_element:nth-child(3) {
    max-width: 185px !important;
  }

  ._inline-form ._form_element:nth-child(4) {
    max-width: 225px !important;
  }
}
@media screen and (max-width: 1045px) {
  ._inline-form ._form_element {
    max-width: unset !important;
  }

  ._inline-form ._form_element:nth-child(3) {
    max-width: unset !important;
  }

  ._inline-form ._form_element:nth-child(4) {
    max-width: unset !important;
  }
}