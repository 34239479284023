body.nav-three-row-nav {
  &:not(.header-transparent) {
    padding-top: 208px;
  }

  header {
    background: #fff;

    &.sticky.is-active {
      background: #fff;
    }

    .top-bar {
      height: 32px;
      background-color: #f00;
    }

    .logo-container {
      height: 125px;

      > .container,
      .container-fluid {
        height: 100%;
      }

      .right {
        .phone {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .btn {
          margin-left: 15px;
        }
      }
    }

    .social-link {
      border: 1px solid #fff;
      /* width: 30px;
       height: 30px;*/
      margin: 0 0 0 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;

      &:hover {
        text-decoration: none;
      }
    }

    .navbar {
      height: 52px;

      .top {
        text-align: right;
        //width: 100%;
      }

      .navbar-nav {
        //padding-bottom: 5px;
      }

      li {
        padding: 0 5px;
      }

      .main-nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 0;
      }
    }
  }
  /*header*/
  header.nav-layout-nav-center {
    nav.navbar {
      .main-nav {
        justify-content: center !important;
      }
    }

    &.has-social {
      nav.navbar {
        .main-nav {
          .navbar-nav {
            width: calc(100% - 150px);
            justify-content: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: $media_md_) {
    header {
      .navbar {
        .top {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: $media_sm_) {
    .logo-container {
      .right {
        display: flex;
        text-align: right;
        flex-direction: column;

        .phone {
          margin-bottom: 5px;
        }
      }
    }

    &.nav-mobile header .navbar-toggler .material-icons {
      padding: 5px;
    }
  }
  @media screen and (orientation: portrait) {
    .div-for-mobile {
      padding-top: 250px;
    }
  }
}
/*==sticky heaeder*/
body.nav-three-row-nav {
  header.sticky {
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
  }

  &.header-sticky header.is-active,
  &.header-sticky header.is-active.has-signup {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}