//@import "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css";
//@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-multiselect/0.9.15/css/bootstrap-multiselect.css";
//@import "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css";
//@import "https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css";
//@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";
//@import "https://fonts.googleapis.com/icon?family=Material+Icons";
//@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css";
@import url("fonts/stylesheet.css"); //
/*defauts*/
$color_blue_dark: #1E1A34;
$color_blue: #365F9F;
$color_blue_light: #0083C4;
$color_blue_extra_light: #5DADDC;
$color_black: #212721;
$color_medium_black: #9B9B9B;
$color_line_black: #D8D8D8;
$color_light_black: #E5E5E5;
$color_white: #fff;
$color_bg_gray: #F4F4F4;
$color_bg_gray_light: #F1F1F1;
$color_green_light: #43B02A;
$color_red: #EA6060;
$color_red_dark: #D22727;
$color_orange: #ff4d54;
$media_xs: 575px;
$media_xs_: 576px;
$media_sm: 767px;
$media_sm_: 768px;
$media_md: 991px;
$media_md_: 992px;
$media_lg: 1200px;
$media_xl: 1281px;
$media_xxl: 1400px;
$fw_extra_bold: bold;
$fw_medium: 500;
$fw_thin: normal;
$padding_section_gray: 9.375em;
$padding_section_gray_small: 6.375em;
$padding_section: 6.25em;
$padding_section_small: 3.75em;
$padding_content: 3.125em;
$padding_content_small: 1.25em;
$padding_content_laptop: 1.5em;
$padding_small: 4em;
$padding_extra_small: 4.375em;
$padding_section_extra_small: calc(2.5em + 2vw);
$padding_content_extra: 10em;
$fz_size_big: 5em;
$fz_size1: 2.25em;
$fz_size2: 1.5em;
$fz_size3: 1.25em;
$fz_size4: 0.875em;
$fz_20: 1.25em;
$fz_14: 0.875em;

/*mixins*/

@function size-with-vw($narrowsize, $widesize, $narrowwidth:320, $widewidth:992) {
  $sizediff: ($widesize - $narrowsize);
  $widthdiff: ($widewidth - $narrowwidth);

  @return calc(#{$narrowsize}px + #{$sizediff} * (100vw - #{$narrowwidth}px) / #{$widthdiff});
}


@mixin fluid-size($min:16px, $font:2vw, $max:20px) {
  font-size: clamp($min, $font, $max);
  @supports not (font-size: clamp($min, $font, $max)) {
    font-size: calc(min(max($min, $font), $max));
  }
}


@keyframes ClipRectIn {
  0% {
    visibility: hidden;
    clip-path: inset(0% 0px 100% 0px);
    transform: translateY(50%);
    //transform: matrix(1, 0, 0, 1, 0, 150);
  }

  100% {
    visibility: visible;
    clip-path: inset(0% 0px 0% 0px);
    transform: translateY(0%);
    //transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

.container {
  position: relative;
  z-index: 1;
}
@media screen and (max-width: $media_xl) and (max-height: 600px) {
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: $media_xs) and (max-height: 600px) {
  body {
    font-size: 16px !important;
  }
}

a {
  text-decoration: underline;

  span.fileExt {
    display: none;
  }
}

a,
a > i {
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.tdn {
  text-decoration: none;

  > a {
    text-decoration: none;
  }
}

.disabled {
  pointer-events: none;
}

.hide {
  display: none;
}

.reverse {
  transform: rotate(180deg);
}

.content {
  //.size-3, h3 {
  //  font-size: 16px;
  //}
}
@media screen and (max-width: $media_lg) {
  .size-big {
    margin-top: 12vmin;
    font-size: 11vmin;
    > span {
      @include fluid-size(20px, 8.25vw, 80px);
    }
  }
}
@media screen and (max-width: $media_xs) {
  .size-big {
    font-size: 10vmin !important;
    line-height: 1.25;
  }

  .size-1,
  h1 {
    font-size: 5.8vmin;
    line-height: 1.16;
  }
  //.size-2 {
  //  line-height: 49px !important;
  //}
  //
  //.size-3, h3 {
  //  line-height: 37px;
  //}
  //
  //.size-4, h4 {
  //  line-height: 36px;
  //}
  //
  //.size-5, h5 {
  //  line-height: 24px;
  //}
}

%noselect,
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Opera and Firefox */
}

.clearfix {
  clear: both;
  width: 100%;
  display: block;
}

.bg-red {
  background-color: $color_red;
}

.bg-green {
  position: relative;

  &.half {
    background-color: transparent;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 175px);
      //background-color: $color_green_light;
    }
  }

  .content {
    a {
      color: #fff !important;
    }
  }
  @media screen and (max-width: $media_sm) {
    &.half {
      .placeholder {
        position: relative;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          bottom: -15px;
          height: 100px;
          width: 120%;
          left: -10%;
          z-index: -1;
          background-color: #fff;
        }
      }
    }
  }
}

%btn,
.btn {
  cursor: pointer;
  text-decoration: none;
  //text-transform: uppercase;
  //height: 44px;
  min-width: 12.5em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  //transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: none !important;

  &:focus,
  &:hover {
    outline-style: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &:active {
    box-shadow: none !important;
    background: none !important;
    border: none !important;
  }

  &.big {
    //height: 50px;
    //padding: 17px 45px;
    //min-width: 100px;
  }

  &.small {
    height: auto;
    //padding: 12px 15px;
    //font-size: 14px;
  }
  position: relative;
}

.btn-primary {
  //color: $color_white !important;
  position: relative;
  z-index: 1;
  background: none;
  border: none;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &:hover {
    background: none;
    border: none;

    &:before {}
  }
  @media screen and (max-width: $media_xl) {
    min-width: 200px;
  }
}

.btn-red {
  //background-color: $color_red !important;
  //border: 1px solid $color_red !important;
  color: $color_white !important;
  position: relative;
  z-index: 1;

  &:hover {
    //background-color: $color_red_dark !important;
    //border: 1px solid $color_red_dark !important;
  }
}

.btn-white {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  position: relative;
  z-index: 1;

  &:hover {
    //background-color: $color_red_dark !important;
    //border: 1px solid $color_red_dark !important;
    color: #fff !important;
  }

  &.active {
    //background-color: $color_red !important;
    //border: 1px solid $color_red !important;
    color: #fff !important;
  }
}

.btn-light-black {
  //background-color: $color_medium_black !important;
  //border: 1px solid $color_medium_black !important;
  color: #fff !important;
  position: relative;
  z-index: 1;

  &:hover {
    //background-color: $color_red_dark !important;
    //border: 1px solid $color_red_dark !important;
    color: #fff !important;
  }

  &.active {
    //background-color: $color_red !important;
    //border: 1px solid $color_red !important;
    color: #fff !important;
  }
}

.btn-apply {
  padding-left: 20px;
  padding-right: 15px;

  i {
    margin: -5px 0 0 5px;

    &:hover {
      color: #fff;
    }
  }
}
/*==pattern*/
img.pattern {
  position: absolute;
  z-index: 9;
  pointer-events: none;
  left: -$padding_section;
  top: -$padding_section_gray;

  &.bottom {
    top: auto;
    bottom: -$padding_section_gray;
  }

  &.right {
    left: auto;
    right: -$padding_section;
  }
  @media screen and (max-width: $media_lg) {
    top: -$padding_section;

    &.bottom {
      bottom: -$padding_section;
    }
  }
}

body.button-style-square {
  .btn {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &:before {
      transform: none;
    }
  }

  section.update .subscription-form .input-group button:before {
    transform: none;
  }

  section.team-section .content-wrapper .team-info .btn-primary {
    min-width: auto;
  }
}

body.button-style-round {
  .btn,
  a.more {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

    &:before {
      transform: none;
    }
  }

  section.update .subscription-form .input-group button:before {
    transform: none;
  }

  section.update .subscription-form .input-group button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    .material-icons {
      position: absolute;
      top: 13px;
      right: 25px;
      display: none;
      color: $color_white;

      &.short {
        right: 12px;
      }

      &.sending {
        display: block;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scrollSide;
      }
    }
  }
}

body.button-style-skew {
  .btn {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &:before {
      top: -1px;
      left: -9px;
      width: calc(100% + 18px);
      height: 102%;
      transform: skewX(40deg);
    }
  }

  section.update .subscription-form .form-group.step4.valid.animate .input-group:after {
    width: 97.5% !important;
  }

  section {
    .image-placeholder {
      &:after,
      &:before {
        content: '';
        position: absolute;
        transform: rotate(45deg);
        top: -25px;
        left: -25px;
        //border: 1px solid $color_white;
        width: 50px;
        height: 50px;
        //background-color: $color_blue_light;
        z-index: 1;
      }

      &:after {
        top: auto;
        left: auto;
        bottom: -25px;
        right: -25px;
      }
    }
  }
}
@media screen and (max-width: $media_xs) {
  .size-1,
  h1 {
    //font-size: 2.6em;
    //line-height: 45px;
  }

  .btn.big {
    font-weight: normal;
  }
}
/*ul-dropdown*/
ul.select {
  height: 30px;
  width: 150px;
  //background-color: $color_bg_gray;
  list-style: none;
  text-align: left;

  li {
    padding: 5px 10px;
    z-index: 2;

    &:hover {
      cursor: pointer;
    }
  }

  li:not(.init) {
    display: none;
    background: #ddd;
  }

  li.selected:not(.init),
  li:not(.init):hover {
    //background: $color_blue_light;
    color: #fff;
  }

  li.init {
    cursor: pointer;
  }
}

.section-title > div {
  font-size: 0.875em;
  font-weight: bold;
  letter-spacing: 0.075em;
  line-height: 1.5em;
  text-transform: uppercase;
  position: relative;

  .number {
    width: 60px;
    float: left;

    &.underline {
      color: rgba(0, 0, 0, 0);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 2px;
        width: 60px;
        max-width: 100%;
      }
    }
  }

  a.blog-categories,
  a.blog-categories:hover {
    //color: $color_blue;
  }
}

section {
  //padding-top: $padding_section;
  //padding-bottom: $padding_section;
  width: 100%;
  float: left;

  .container.has-pattern.top {
    //padding-top: $padding_section;
  }

  .heading-container {
    .size-3 {
      margin-bottom: $padding_content_small;
      text-transform: uppercase;
    }
  }

  .section-title {
    margin-bottom: $padding_content;
  }

  .section-content {
    position: relative;

    .content {
      //line-height: 56px;
    }
  }

  .block-view-all {
    margin-top: $padding_section_small;
  }

  .image-placeholder {
    position: relative;

    .inner-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $color_white;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-height: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &:not(.bg-gray) {
    .container.has-pattern.bottom {
      //padding-bottom: $padding_section;
    }
  }

  &.bg-primary-light-color {
    .heading-container {
      .size-1,
      .size-3 {
        color: $color_white;
      }
    }
  }
  @media screen and (max-width: $media_xs) {
    .heading-container {
      .size-3 {
        margin-bottom: 15px;
      }
    }
  }
}
@media screen and (max-width: $media_lg) {
  section {
    //padding-top: $padding_extra_small;
    //padding-bottom: $padding_extra_small;
    &.last-section {
      border-bottom: $padding_extra_small solid rgba(255, 255, 255, 0);
    }

    .section-title {
      margin-bottom: $padding_extra_small;
    }
  }
}
@media screen and (max-width: $media_xs) {
  section {
    &:not(.bg-gray) {
      .container.has-pattern.bottom {
        //padding-bottom: $padding_content;
      }
    }

    .image-placeholder {
      img {
        &.active {
          position: relative;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.container > .bg-pattern {
  position: absolute;
  width: 248px;
  height: 248px;
  max-width: 50%;
  left: -80px;
  top: -200px;
  z-index: -1;
  -webkit-background-size: cover !important;
  background-size: cover !important;

  &.bottom {
    top: auto;
    bottom: -200px;
  }

  &.right {
    right: -200px;
    left: auto;
  }

  &.right.bottom {
    top: auto;
    right: -80px;
  }
}

.pattern-wrapper {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  img {
    position: absolute;
    left: -50px;
    top: -50px;

    &.right {
      right: -50px;
      left: auto;
    }

    &.bottom {
      top: auto;
      bottom: -260px;
    }
  }
  @media screen and (max-width: $media_lg) {
    img {
      max-width: 50vmin;

      &.bottom {
        bottom: -150px;
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .pattern-wrapper {
      img {
        &.bottom {
          bottom: -100px;
        }
      }
    }
  }
}

section.bg-gray {
  //padding-top: $padding_section;
  //padding-bottom: $padding_section;
  position: relative;

  .bg-pattern {
    //-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    //filter: grayscale(100%);
  }

  .container {
    z-index: 0;
  }

  .container > .bg-pattern {
    left: -80px;
    top: -150px;
    z-index: -1;

    &.bottom {
      top: auto;
      bottom: -150px;
    }

    &.right {
      right: -80px;
      left: auto;
    }

    &.right.bottom {
      top: auto;
      right: -150px;
    }
  }
}

section.bg-primary-light-color {
  //padding-top: $padding_section_gray;
  //padding-bottom: $padding_section_gray;
  position: relative;
}

section.bg-gray-half {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 50%;
    //background-color: $color_bg_gray;
    z-index: -1;
  }
}

body:not(.Home) {
  section.bg-gray-half.three-block {
    &:after {
      display: none;
    }
  }
}

section.bg-gray-quarter {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 75%;
    //background-color: $color_bg_gray;
    z-index: -1;
  }
}

.no-result-found {
  margin-top: 50px;
}

.owl-next,
.owl-prev {
  padding: 0;
  color: $color_white;

  span {
    opacity: 0.5;
    color: $color_white;
    font-size: 30px;
    width: 60px;
    height: 60px;
    display: block;
    line-height: 50px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

.owl-next:focus,
.owl-next:hover,
.owl-prev:focus,
.owl-prev:hover {
  outline-style: none !important;

  span {
    opacity: 1;
  }
}

a.icon-link {
  i {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &:hover {
    i {
      //transform: translate(5px, -5px);
    }
  }
}

.custom-owl-btn {
  .owl-prev {
    span {
      color: rgba(0, 0, 0, 0);
    }
    position: relative;

    &:after {
      content: "chevron_left";
      position: absolute;
      color: $color_white;
      left: 0;
      right: 0;
      top: 27%;
      margin: 0 auto;
      font-family: 'Material Icons';
      font-style: normal;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-size: 1.25em;
      opacity: 0.5;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  .owl-next {
    span {
      color: rgba(0, 0, 0, 0);
    }
    position: relative;

    &:after {
      content: "chevron_right";
      position: absolute;
      color: $color_white;
      left: 0;
      right: 0;
      top: 25%;
      margin: 0 auto;
      font-family: 'Material Icons';
      font-style: normal;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      font-size: 1.25em;
      opacity: 0.5;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}

body.ie {
  .custom-owl-btn {
    .owl-next,
    .owl-prev {
      span {
        opacity: 0.5;
        color: $color_white;
      }

      &:after {
        display: none;
      }

      &:hover {
        span {
          opacity: 1;
        }
      }
    }
  }
}
/*only edge suports -ms-ime-aligh*/
@supports (-ms-ime-align: auto) {
  .custom-owl-btn {
    .owl-next,
    .owl-prev {
      span {
        opacity: 0.5;
        color: $color_white;
      }

      &:after {
        display: none;
      }

      &:hover {
        span {
          opacity: 1;
        }
      }
    }
  }
}

.toggle-description {
  overflow: hidden;
  position: relative;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    z-index: -1;
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
  }

  .description {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
  }
  /*no hove reffect in mobile*/
  @media screen and (min-width: $media_xs) {
    &:hover {
      .overlay {
        &:before {
          opacity: 1;
        }
        transform: scale(1.1);
      }

      .description {
        overflow: visible;
        max-height: 500px;
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .overlay {
      left: 1px;
      top: -1px;
    }
  }
}

.overlay-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.content,
p {
  line-height: 24px;

  b,
  strong {
    //color: $color_black;
    font-weight: 600;
  }

  > span {
    //color: $color_medium_black;
    a:not(.btn) {
      //color: $color_blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  a:not(.btn) {
    //color: $color_blue;
    span {
      //color: $color_blue;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  a[title="button"] {
    font-size: 14px;
    text-decoration: none;
    font-weight: $fw_medium;
  }
}

.content {
  p > img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;

    &.rightAlone {
      clear: both;
      display: block;
      margin-left: auto;
    }

    &.leftAlone {
      clear: both;
      display: block;
    }

    &.center {
      clear: both;
      display: block;
      margin: 0 auto;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: $media_sm) {
    p > img {
      width: auto !important;
      height: auto !important;
      max-width: 100%;

      &.left {
        float: left;
        padding-right: 10px;
        padding-bottom: 10px;
      }

      &.right {
        float: right;
        padding-left: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.image-placeholder-icon {
  position: relative;
  max-height: 600px;
  overflow: hidden;
  //margin: 1.875em 0 0;
  .overlay {
    height: 40vw;
  }

  &.portrait {
    max-height: 100%;
  }

  &:hover {
    .material-icons {
      opacity: 1;
    }
  }
}
/*==bodycss*/
body,
html {
  position: relative;
  left: 0;
  overflow-x: hidden;
}

body {
  //padding-top: 130px;
  overflow-x: hidden;

  &.has-signup {
    //padding-top: 60px;
    section.banner {
      height: calc(100vh - 180px);
    }
  }

  &.no-banner {
    header {
      //background-color: $color_blue;
      //position: relative;
      nav {
        .dropdown-toggle,
        ul a {
          .fas {
            color: $color_white;
          }
        }

        .dropdown-toggle:hover,
        a:hover,
        li.active > a {
          //color: $color_white;
          .fas,
          i {
            //color: $color_white;
          }
        }

        a.btn-primary {
          //color: $color_blue !important;
          &:before {
            //background-color: $color_white;
          }
        }
      }
    }
  }

  &.Home:not(.header-default) {
    //padding-top: 0;
  }
}

.signup-box {
  padding: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(35, 31, 32, 0.7);

  .btn {
    font-size: 14px;
    margin-left: 20px;
    padding: 5px 39px 0;
    line-height: 17px;
  }

  .left {
    color: #FFFFFF;
    font-weight: $fw_medium;
    margin-bottom: -3px;
    line-height: 24px;
  }

  .fas,
  .material-icons {
    color: #fff;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: $media_sm) {
    .left {
      font-size: 12px;
      font-weight: $fw_medium;
    }

    .btn {
      font-weight: $fw_medium;
      font-size: 12px;
      margin-left: 5px;
      width: 150px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
/*==header*/
@import "modules/header.scss";
@import "modules/nav-three-rows.scss";

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.heading-container {
  .size-4 {
    margin-bottom: 20px;
  }
}
/*==banner*/
@import "modules/banner.scss";
/*==map-wrapper*/
.map-wrapper {
  width: calc(100% - 30px);
  padding-top: 43%;
  bottom: 65px;

  .map-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    /*    &:before {
            content: '';
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(39, 39, 40, 0.2);
          }*/
  }
  @media screen and (max-width: $media_sm) {
    position: relative;
    top: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding-top: 65%;
  }
}

.map-wrapper.layout-a {
  // position: absolute;
  z-index: 2;
  width: calc(100% - 30px);
  padding-top: 72%;
  bottom: 0;
  @media screen and (max-width: 768px) {
    position: relative;
    margin-top: 50px;
    top: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
@import "modules/element-summary.scss";
@import "modules/element-content.scss";
@import "modules/three-block.scss";
@import "modules/section.two-block.scss";
@import "modules/home-for-sale.scss";
@import "modules/latest-news.scss";

footer {
  .subs-container {
    width: 100%;
  }
  @media screen and (min-width: $media_md) {
    .subs-container {
      max-width: 360px;
    }
  }
  @media screen and (max-width: $media_sm) {}
}

body.button-style-skew {
  section.latest-news.bg-gray .content-wrapper:before {
    content: '';
    position: absolute;
    //right: -2.1em;
    //bottom: -2.1em;
    //border: 2em solid transparent;
    //border-top-color: $color_white;
    transform: rotate(-45deg);
    bottom: -25px;
    right: -25px;
    //border-top: 1px solid $color_bg_gray;
    width: 50px;
    height: 50px;
    background-color: $color_white;
  }
}
@media screen and (min-height: $media_sm) {
  .heading-container {
    .flex-item {
      margin-left: 10px;
    }
  }
}
@media screen and (max-width: $media_sm) {
  .heading-container {
    .right {
      display: inline;
    }

    .btn {
      display: none;
    }
  }
}

.pagination {
  margin-top: calc(6.25em - 30px);

  .btn-red,
  .btn-white {
    margin: 0;
    float: left;
    outline-style: none;
  }

  .btn-white {
    border-left: 1px solid $color_bg_gray;
  }
  @media screen and (max-width: $media_xs) {
    margin-top: $padding_content;

    .btn-red,
    .btn-white {
      width: 50px;
      height: 50px;
    }
  }
}

.blog-info {
  padding: 20px 20px 0;

  .brand {
    font-size: 1.25em;
    font-weight: 500;
    //color: $color_black;
    padding: 5px 10px 0 5px;
    //-webkit-border-radius: 20px;
    //-moz-border-radius: 20px;
    //border-radius: 20px;
    text-decoration: none;
    border-left: none !important;
    position: relative;
    z-index: 1;
    margin-left: 12px;

    .trangle {
      z-index: -1;
      width: 17px;
      height: calc(100% + 6px);
      position: absolute;
      left: -7px;
      transform: skewX(-20deg);
      top: -3px;
    }

    .brand-img {
      display: none;
      display: inline-block;
      margin-right: 10px;

      img {
        width: 40px;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .category a {
    font-size: 14px;
    font-weight: $fw_medium;
    //color: $color_medium_black;
    text-decoration: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .published {
    font-size: 12px;
    //color: $color_medium_black;
  }
}

section.information-center {
  //margin: 0;
  //padding: $padding_section_gray 15px;
  .bg-container {
    width: 100%;
    height: 100%;
    padding: $padding_section_gray 15px;
  }

  .bg-gray,
  .bg-red,
  .bg-white {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .bg-red {
    //background-color: $color_red_dark;
    .inner-content,
    .size-1 {
      color: $color_white !important;
    }

    .btn-red:hover {
      border: 1px solid $color_red !important;
    }
  }

  .container {
    .bg-pattern {
      left: -80px;
      top: -200px;
      z-index: -1;

      &.bottom {
        top: auto;
        bottom: -200px;
      }

      &.right {
        right: -50px;
        left: auto;
      }

      &.right.bottom {
        top: auto;
        right: -200px;
      }
    }

    .content {
      padding: 4.3em 0;
      width: 100%;
    }

    .inner-content {
      margin: 12px 0 30px;
      color: $color_medium_black;
    }
  }

  .bdr {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 90%;
      left: 5%;
      bottom: 0;
      background-color: $color_white;
    }
  }
  @media screen and (min-width: $media_md) {
    .bdr {
      border-right: 1px solid $color_black;

      &:after {
        display: none;
      }
    }

    .bg-red {
      .bdr {
        border-right: 1px solid $color_white;
      }
    }
  }
  @media screen and (max-width: $media_xs) {
    padding: $padding_content 15px;

    .container .content {
      padding: $padding_content 15px;
    }
  }
}
/*==two block*/
@import "modules/element-block-list.scss";

section.hightlight {
  .content {
    width: calc(100% - 60px);
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 20px;
    min-height: 197px;
    position: relative;

    .inner-content {
      margin: 10px 0 15px;
      color: #fff;
      position: relative;
    }

    h3,
    p {
      color: #fff;
    }
  }

  .placeholder {
    height: 500px;
    margin-bottom: -120px;
  }

  ol {
    padding: 10px 0 0;
    list-style: none;
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
      padding-left: 55px;
      min-height: 50px;
      margin: 15px 0;
      //color: $color_medium_black;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:before {
        content: counter(my-awesome-counter);
        color: #FFF;
        font-weight: bold;
        margin-right: 10px;
        width: 50px;
        height: 50px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        background: $color_red;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 10px;
        font-family: 'Calibre';
      }
    }
  }
}

section.two-block-no-image {
  .inner-content {
    margin-top: 15px;
    //color: $color_medium_black;
    p {
      //color: $color_medium_black;
    }
  }
}

body.Blog {
  .intro {
    .categories {
      position: relative;
      height: 47px;

      .btn {
        span {
          padding-right: 15px;
          position: relative;
          display: block;
          text-transform: uppercase;

          .material-icons {
            position: absolute;
            right: -15px;
            top: -1px;
            font-size: 16px;
          }
        }
      }

      .dropdown {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 1px solid $color_medium_black;
        background-color: $color_white;
        padding: 0;

        span {
          padding: 0 75px 0 15px;
          position: relative;
          display: flex;
          font-size: 12px;
          font-weight: $fw_medium;
          text-transform: uppercase;
        }

        .material-icons {
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;
          height: 44px;
          color: #fff;
        }

        &:active,
        &:focus,
        &:hover {
          outline-style: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }

      ul {
        display: none;
        position: absolute;
        width: 100%;
        top: 46px;
        right: 0;
        background-color: #fff;
        padding: 15px;
        list-style: none;
        border: 1px solid $color_medium_black;
        border-top: 0;

        li {
          display: block;

          &:not(last-child) {
            margin-bottom: 10px;
          }

          a {
            color: $color_medium_black;
            text-decoration: none;

            &.active,
            &:hover {
              //color: $color_blue;
            }
          }

          &.active {
            a {
              //color: $color_blue;
            }
          }
        }
      }

      &:hover {
        ul {
          display: block;
        }
      }
    }
  }

  .latest-news {
    .content-wrapper {
      padding-bottom: 0;

      .more {
        margin-top: 20px;
        margin-bottom: 10px;
        position: relative;
        left: 0;
        bottom: 0;
        display: block;
      }
    }
  }

  .block-testimonial {
    .bg-primary-light-color {
      //background-color: $color_blue_light !important;
    }

    h5 {
      //font-size: 32px;
      //line-height: 1.156;
      color: $color_white;
    }

    .by {
      margin-top: 40px;
      font-size: 14px;
      color: $color_white;
      //margin-bottom: 10px;
    }

    .content {
      background: transparent url("../images/quote.png") 5px 25px no-repeat;
      -webkit-background-size: 111px;
      background-size: 111px;
      padding: 70px 30px;
    }
  }
  @media screen and (min-width: $media_md) {
    .heading-container {
      .categories {
        a:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

section.blog-single-image {
  padding-bottom: $padding_content_laptop;

  .back {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;

    .material-icons {
      position: absolute;
      top: -3px;
      left: -30px;
    }
  }

  .image-placeholder-icon {
    //margin-top: 20px;
    .inner-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 450px;
      position: relative;
      top: 0;
      left: 0;
      transform: none;

      &.portrait {
        height: auto;
      }
    }
  }

  &.GrayBackgroundColor,
  &.bg-gray {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      bottom: 0;
      left: 0;
      height: 25%;
      background-color: $color_white;
    }
  }
}

section.blog-single {
  .published {
    font-size: 12px;
    line-height: 24px;
    color: $color_medium_black;
    display: block;
  }

  .category a {
    font-size: 12px;
    line-height: 24px;
  }

  .size-1 {
    text-transform: none;
  }

  .category {
    a {
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  h4 {
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
  }

  .summery {
    margin-bottom: $padding_content_laptop;
  }

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $padding_content_small;
    margin-bottom: $padding_content_laptop;

    .brand {
      text-decoration: none;
      font-size: 1.25em;
      font-weight: 500;
      color: $color_black;
      padding: 5px 10px 0 5px;
      background-color: #fff;
      position: relative;
      margin-left: 15px;
      //-webkit-border-radius: 20px;
      //-moz-border-radius: 20px;
      //border-radius: 20px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }

      .trangle {
        z-index: -1;
        width: 17px;
        height: calc(100% + 6px);
        position: absolute;
        left: -10px;
        transform: skewX(-20deg);
        top: -3px;
      }

      .brand-img {
        display: none;
        margin-right: 10px;

        img {
          width: 40px;
        }
      }
    }

    .category {
      a {
        font-weight: 600;
        font-size: 14px;
        text-decoration: none;
        color: $color_medium_black;
      }
    }
  }

  .main-blog-content {
    .content {
      img {
        max-width: 100%;
      }
    }

    .more {
      margin-top: $padding_section;
      display: block;
      //color: $color_blue;
      text-decoration: none;
      align-items: center;

      i {
        //color: $color_blue;
      }
    }

    .social {
      margin-top: $padding_section;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        display: inline-block;
        //color: $color_blue;
        margin-right: 20px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;

        a {
          //color: $color_blue;
          i {
            font-size: 20px;
          }
        }
      }

      &.icon-style-round {
        li {
          a {
            width: 40px;
            height: 40px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: $color_white;
          }
        }
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .main-blog-content {
      .social {
        margin-top: 1.875em;
      }
    }
  }
}
/*==sectionfacebook*/
section.follow-on-facebook {
  padding: 2.5em 0;

  a {
    //color: $color_black;
    //font-family: 'Calibre';
    text-decoration: none;
    text-transform: uppercase;
  }

  .fab {
    color: #fff;
    //background-color: $color_black;
    margin-left: 10px;
    padding: 15px 20.5px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    &:hover {
      //background-color: #000;
    }
  }
  @media screen and (max-width: $media_sm) {
    h3 {
      font-size: 22px;
    }
  }
}
@import "modules/for-sale-carousel.scss";

section.bg-white + section.bg-white {
  //padding-top: 0;
}

section.single-quote + section,
section:not(.bg-gray) + footer {
  //margin-top: $padding_section;
}

section.bg-gray + section.bg-gray,
section.bg-green.half + section.wide-image,
section.empty + section.two-block,
section.follow-on-facebook + footer,
section.follow-on-facebook + section.bg-gray,
section.for-sale-carousel + section.bg-gray,
section.for-sale-carousel + section.follow-on-facebook,
section.information-center + section.follow-on-facebook,
section.information-center + section.home-for-sale,
section.map-section + footer,
section.map-section + section.bg-gray,
section.two-block-blue + .two-block.related-section,
section.two-block-blue + section.gallery-item,
section.two-block-blue + section.latest-news {
  //margin-top: 0;
}

section.map-container + section.empty + section.two-block.bg-gray {
  //padding-top: $padding_section;
}

section.bg-green.half + section.empty,
section.two-block-small + section.empty {
  display: none;
}

section.two-block-small + section.empty + section.two-block {
  //padding-top: 0;
}

header.header + section.map-section {
  margin-top: $padding_content;
}

section.bg-gray + footer,
section.bg-primary-light-color + footer {}

section.faq {
  .heading-container {
    margin-bottom: $padding_content_small;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      h4 {
        text-transform: initial;
      }
      padding: 1.875em 1.875em 15px 0;
      border-bottom: 1px solid $color_line_black;
      text-align: left;
      position: relative;

      &:first-child {
        //margin-top: -1.875em;
      }

      &:hover {
        cursor: pointer;
      }

      &:after {
        content: '\e5c5';
        //content: '\e145';
        font-family: "Material Icons";
        position: absolute;
        right: 0;
        top: 1.1em;
        //width: 16px;
        //height: 16px;
        font-size: 24px;
        //background: transparent url(../images/plus-green.png) center center no-repeat;
        //-webkit-background-size: contain;
        //background-size: contain;
        -webkit-transition: all 0.1s ease 0s;
        -moz-transition: all 0.1s ease 0s;
        -ms-transition: all 0.1s ease 0s;
        -o-transition: all 0.1s ease 0s;
        transition: all 0.1s ease 0s;
        //-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        //filter: grayscale(100%);
      }

      &.active:after {
        content: '\e5c7';
        font-family: "Material Icons";
        //background: transparent url(../images/minus-green.png) center center no-repeat;
        //-webkit-background-size: 25px;
        //background-size: 25px;
      }

      .content {
        display: none;
        padding-bottom: 1.875em;
        padding-top: 0.5em;
      }

      &.active {
        .content {
          display: block;
        }
      }
    }
  }

  &.inner-faq {
    ul {
      li {
        &:after {
          top: 1.9em;
          width: 18px;
          height: 18px;
          background: transparent url("../images/plus-green.png") center center no-repeat;
          -webkit-background-size: contain;
          background-size: contain;
        }

        &.active:after {
          background: transparent url("../images/minus-green.png") center center no-repeat;
          -webkit-background-size: 18px;
          background-size: 18px;
          -webkit-background-size: contain;
          background-size: contain;
        }
      }
    }
  }
}

section.single-quote {
  padding: $padding_section;
  margin-top: 0;

  &.bg-black,
  &.bg-blue {
    h2,
    h3 {
      color: #fff;
    }
  }
  @media screen and (max-width: $media_sm) {
    padding: $padding_content 15px;
  }
}

section.three-block-no-image {
  margin-top: $padding_content;

  .inner-content {
    //color: $color_medium_black;
  }
}
/*==video pupup*/
.overall-vision .popup-video-wrapper .popup-video {
  //position: relative;
  //height: 292px;
  //display: -webkit-box;
  //display: -moz-box;
  //display: ms-flexbox;
  //display: -webkit-flex;
  //display: flex;
  //align-items: center;
  //justify-content: center;
}

.popup-video-play {
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .material-icons {
    font-size: 80px;
    cursor: pointer;
    opacity: 0.5;
    color: #fff;
    position: relative;
    z-index: 99;

    &:hover {
      opacity: 1;
    }
  }

  .next,
  .prev {
    display: none;
  }
}

.has-title {
  .popup-video-play {
    left: 75%;
  }
  @media screen and (max-width: $media_md) {
    .popup-video-play {
      top: 80%;
    }
  }
  @media screen and (max-width: $media_xs) {
    .popup-video-play {
      left: 80%;
      top: 77%;
    }
  }
}

#popup-video {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.50s ease;
  transition: opacity 0.50s ease;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  max-width: 0;
  max-height: 0;
}

#popup-video.open {
  visibility: visible;
  z-index: 99999;
  opacity: 1;
  max-width: 100%;
  max-height: 100%;

  .popup-video-close {
    cursor: pointer;

    .material-icons {
      color: #fff;
      font-size: 60px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

#popup-video .popup-video-close-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#popup-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

#popup-video .iframe-wrapper {
  position: absolute;
  z-index: 2;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: inline-flex;

  iframe {
    min-width: 70vw;
    min-height: 40.1vw;
    max-height: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  #popup-image,
  #popup-video-internal {
    //min-width: 50%;
    //min-height: 50%;
    max-width: 70vw;
    max-height: 40.1vw;
    width: auto;
    height: auto;
  }

  &.video {
    #popup-image,
    #popup-video-internal {
      display: none;
    }
  }

  &.image {
    #popup-video-internal,
    #popup-video-player {
      display: none;
    }
  }

  &.internal-video {
    #popup-image,
    #popup-video-player {
      display: none;
    }
  }
}

#popup-video .iframe-wrapper .popup-video-close {
  position: absolute;
  top: -60px;
  right: -60px;
}

section.map-section {
  .container {
    position: relative;
    z-index: 1;
  }

  .heading-container {
    margin-bottom: $padding_content;
  }

  .bg-pattern.top {
    top: -100px;
  }
}
@import "modules/single-image.scss";

.alt-text {
  font-size: 12px;
  position: relative;
  margin-top: 12px;
  margin-left: 34px;

  &:before {
    content: '';
    position: absolute;
    //background-color: $color_blue_light;
    left: -14px;
    bottom: 0;
    width: 4px;
    height: 100px;
  }
}
/*==contact*/
section.contact-intro {}

section.inner-form-container {
  .contact-info {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 25px;
      display: block;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      span {
        //color: $color_blue;
        position: absolute;
        left: 0;
        top: 4px;
        font-weight: bold;
        font-size: 12px;
      }

      a {
        // color: $black;
        text-decoration: none;
      }
    }
  }

  .social {
    margin-top: 2.5em;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      display: inline-block;
      margin-right: 20px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;

      &:first-child:not(.calendy) {
        margin-right: 30px;
      }

      a {
        i {
          font-size: 16px;
          position: relative;

          div {
            position: absolute;
            font-size: 7px;
            top: 6px;
            left: 5px;
          }
        }
      }
    }

    &.icon-style-round {
      li {
        a {
          width: 40px;
          height: 40px;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: $color_white;

          &.calendy-text {
            width: auto;
            height: auto;
            background-color: transparent;
            border-right-style: none;
            color: $color_medium_black;
            text-transform: initial;
          }
        }
      }
    }
  }

  .form-container {
    margin-top: $padding_section;

    .size-3 {
      padding-bottom: $padding_content_small;
    }
  }

  .description {
    //color: $color_medium_black;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 50px;
    margin-top: 80px;
  }

  .form-group {
    margin-bottom: 15px;
    position: relative;

    .fas {
      position: absolute;
      right: 20px;
      top: 15px;
      color: $color_red;
      opacity: 0;
    }
  }

  form .form-control {
    border: 1px solid $color_medium_black;
    padding: 13px 20px;
    outline-style: none;
    border-radius: 0;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    height: auto;
    color: $color_black !important;

    &:focus:invalid {
      border: 1px solid $color_red;

      & ~ .fas {
        opacity: 1;
      }
    }

    &.visited:not(:focus):invalid {
      border: 1px solid $color_red;

      & ~ .fas {
        opacity: 1;
      }
    }
  }

  textarea.form-control {
    height: 250px;
  }

  .form-control:focus {
    box-shadow: none !important;
    border-color: $color_light_black;
    outline: 0;
    //box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

  .btn-submit {
    height: 50px;
    min-width: 110px;

    .material-icons {
      position: absolute;
      top: 13px;
      right: 25px;
      display: none;

      &.short {
        right: 12px;
      }

      &.sending {
        display: block;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scrollSide;
      }
    }
  }

  .successfully-sent {
    .message-sent span.default {
      display: none;
    }

    .message-sent span.success {
      display: inline-block;
      font-style: italic;
    }

    .form-group .fas {
      opacity: 0 !important;
    }

    .form-control {
      border: 1px solid $color_medium_black !important;
    }
  }

  #error-message {
    font-size: 12px;
    //color: $color_orange;
    display: none;
  }

  .message-sent span {
    //color: $color_green_light;
    margin-top: 18px;
    font-size: 12px;
    display: none;

    &.default {
      display: block;
    }
  }

  .successfully-sent .form-control {
    //border-color: $color_green_light !important;
  }

  #error-message.show {
    display: block;
  }

  ::placeholder {
    color: #8D8D8D !important;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #8D8D8D !important;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #8D8D8D !important;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: #8D8D8D !important;
  }
  @media screen and (max-width: $media_sm) {
    padding-top: $padding_section_small;

    .form-container {
      margin-top: $padding_section_small;
    }

    .inner-form-wrapper {
      margin-top: 40px;
    }

    .message-sent span {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}

section.inner-form-container.input-style-filled {
  form {
    .form-control {
      background-color: $color_bg_gray;
      border-color: $color_bg_gray;
    }

    .successfully-sent {
      .form-control {
        border: 1px solid $color_bg_gray !important;
      }
    }
  }
}
/*==gallery*/
section.gallery-item {
  .tag-section {
    display: none;
    margin-bottom: $padding_content_laptop;

    ul {
      display: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;
      }

      li:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .filter-selected {
    margin-bottom: $padding_content_laptop;
    background-color: $color_line_black;
    padding: 10px;

    ul {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;

      li:not(:last-child) {
        margin-right: 10px;
      }

      .material-icons {
        margin-top: -5px;
        margin-left: 5px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .heading-container {
    margin-bottom: 2.5em;
    justify-content: center;

    h1,
    h2,
    h3 {
      margin-bottom: 0;
      align-self: center;
    }

    .size-1 {
      .material-icons {
        font-size: 25px;
        float: left;
        margin-top: 3px;
        margin-right: 25px;
        color: $color_red;
      }
    }

    .inner-content {
      position: relative;
      width: 100%;

      .line {
        position: absolute;
        bottom: -15px;
        width: 100%;
        height: 1px;
        left: 0;
        border-top: 1px solid $color_line_black;
      }
    }

    .right {
      display: inline-flex;
      margin-top: -10px;
    }
  }

  .content-wrapper {
    border: 1px solid $color_bg_gray_light;
    position: relative;
    background-color: #fff;
    width: 100%;
    margin-bottom: 0;

    .popup-video-play {
      position: relative;
    }

    .content {
      padding: 20px;

      .size-4 {
        color: $color_black;
      }

      .inner-content {
        margin: 15px 0 0;
        color: $color_medium_black;
      }
    }

    .placeholder {
      min-height: 250px;
      position: relative;
      overflow: hidden;

      i {
        font-size: 60px !important;
        color: #fff;
        opacity: 0.8;
        position: relative;
        z-index: 999;

        &:hover {
          opacity: 1;
        }
      }

      .inner-image {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background: $color_black;
          width: 100%;
          height: 100%;
          opacity: 0.1;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }

        &.portrait {
          background-position: top left !important;
        }
      }

      &:hover {
        .inner-image {
          transform: scale(1.2);

          &:after {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .gallery-grid-container {
    margin-bottom: -30px;

    .col-md-4 {
      margin-bottom: 30px;

      .size-4 {
        position: absolute;
        color: $color_white;
        text-transform: uppercase;
        left: 20px;
        bottom: 0;
        width: calc(100% - 40px);
      }
    }

    .col-md-3 {
      margin-bottom: 30px;
    }
  }
  //@media screen and (min-width: $media_xl){
  //  .gallery-grid-container {
  //    margin-bottom: -30px;
  //    .col-md-4,  .col-md-3 {
  //      margin-bottom: 30px;
  //    }
  //  }
  //}
  @media screen and (min-width: $media_md) {
    .heading-container {
      .btn-gray {
        width: 150px;
        margin-left: 10px;
      }
    }

    .tag-section {
      ul {
        margin-bottom: -10px;
      }
    }
  }
  @media screen and (min-height: $media_sm) {
    .heading-container {
      .flex-item {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .heading-container {
      text-align: center;
      justify-content: center;

      .right {
        display: inline;
      }

      .btn {
        //display: none;
      }
    }
  }
}

body.Album,
body.Gallery {
  section.gallery-item {
    padding-bottom: $padding_section_gray;
  }
  @media screen and (max-width: $media_sm) {
    .gallery-grid-container .col-md-4 {
      margin-bottom: 15px;
    }
  }
}

body.Album,
body.BlogItem {
  section.gallery-item .content-wrapper .placeholder {
    min-height: 182px;
  }

  #popup-video {
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;

    .iframe-wrapper {
      position: relative;
      max-width: 70vw;
      max-height: 40.1vw;
      left: auto;
      top: auto;
      -webkit-transition: all 0.3s ease-out 0.5s;
      -moz-transition: all 0.3s ease-out 0.5s;
      -ms-transition: all 0.3s ease-out 0.5s;
      -o-transition: all 0.3s ease-out 0.5s;
      transition: all 0.3s ease-out 0.5s;
      opacity: 0;
    }

    &.animate {
      .iframe-wrapper {
        opacity: 1;
      }
    }

    #popup-video-player {
      padding-bottom: 1px;
    }

    #popup-image,
    #popup-video,
    #popup-video-player {
      border: 1px solid #fff;
    }

    .popup-video-close {
      right: 0;
      top: -30px;
      display: flex;
      align-items: center;

      span {
        display: inline-block !important;
        color: #fff;
        padding-top: 5px;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Calibre';
        text-transform: uppercase;
      }

      .material-icons {
        opacity: 1;
        font-size: 16px;
        color: #fff;
        margin-left: 10px;
      }
    }

    .progress-content {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 10px;
      color: #FFFFFF;
      font-family: Calibre;
      font-size: 12px;
    }

    .next,
    .prev {
      position: absolute;
      display: block;
      top: calc(50% - 40px);
      z-index: 9;
      cursor: pointer;
      @extend %noselect;

      .material-icons {
        color: #fff;
        font-size: 40px;
      }
    }

    .prev {
      left: -90px;
    }

    .next {
      right: -90px;
    }
  }
  @media screen and (max-width: $media_xs) {
    .gallery-grid-container .item {
      margin-bottom: 15px;
    }

    #popup-video {
      .prev {
        left: -40px;
        top: 45%;
      }

      .next {
        right: -40px;
        top: 45%;
      }
    }
  }
}

body.BlogItem {
  section.gallery-item .content-wrapper .placeholder {
    min-height: 169px;
  }
  @media screen and (max-width: $media_sm) {
    .blog-single-image {
      padding-top: 0;

      .image-placeholder-icon {
        margin-left: -15px;
        width: calc(100% + 30px);
      }

      a.back {
        max-width: 124px;
        margin-left: -15px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 18px 19px;
        color: #fff;
        //background-color: #0083C4;
        height: 50px;

        .material-icons {
          position: relative;
          left: initial;
          top: initial;
          padding-right: 10px;
        }
      }
    }
  }
}

#download-form {
  display: none !important;
}
/*section grid block*/
/*
  == picture block
  */
section.grid-block {
  &.bg-gradient {
    background-image: linear-gradient(#fff, #f1f1f1);
  }

  .col-md-4 {
    border: 10px solid #fff;
    min-height: 320px;
    text-align: center;
    position: relative;
  }

  .col-md-4:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .content {
    padding: 25px;
    margin: 0 auto;
    position: relative;
  }

  .content {
    .title {
      font-size: 85px;
      color: #fff;

      small {
        color: #fff;
        font-size: 20px;
        margin-bottom: 20px;
        display: block;
      }
    }

    .description {
      color: #fff;
      font-size: 20px;
      margin-top: 1.5em;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: $media_xs) {
    .col-md-4 {
      border: 15px solid $color_white;
      border-top: 0;

      &:first-child {
        border-top: 15px;
      }
    }
  }
}
@import "modules/testimonial.scss";
@import "modules/video.scss";
@import "modules/logo-panel.scss";
/*subscription*/
section.update {
  position: relative;
  /*&:after {
      content: '';
      width: 100%;
      height: 50%;
      background-color: $color_bg_gray;
      position: absolute;
      left: 0;
      bottom: 0;
    }*/
  .subscription-form {
    margin-top: $padding_content;
    display: block;

    label.steps {
      font-size: 0.75em;
      line-height: 1.5em;
      position: absolute;
      right: 130px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
    }

    button,
    button:focus,
    input,
    input:focus {
      outline-style: none !important;
      border-color: transparent !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    input {
      padding: 13px 40px 13px 20px;
      height: auto;
      font-size: 16px;
      border-radius: 0;
    }

    .input-group {
      position: relative;
      border: none;
      border-bottom-width: 4px;

      button {
        width: 120px;
        height: calc(100% - 3px);
        margin-top: 2px;
        overflow: hidden;
        position: relative;

        &:before {
          position: absolute;
          content: '';
          left: -22px;
          top: -1px;
          width: 123%;
          height: 102%;
          //background-color: $color_blue;
          transform: skewX(-40deg);
          z-index: -1;
        }

        &:hover {
          &:before {
            //background-color: $color_blue_dark;
            color: $color_white !important;
          }
        }
      }

      button {
        div {
          //transform: rotate(180deg);
          color: $color_white;

          i {
            padding-right: 10px;
            margin-top: 5px;
            color: $color_white;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
            position: absolute;
            right: 8px;
            top: 8px;

            &.sending {
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: scrollSide;
              //animation-direction: reverse;
            }
          }
        }

        &:hover {
          i {
            color: $color_white;
          }
        }
      }
    }

    .input-group-append {
      position: relative;
      z-index: 4;
      margin: -3px;
    }

    .input-group:after {
      content: '';
      position: absolute;
      width: 0;
      left: 0;
      bottom: 0;
      height: 2px;
      background-color: $color_green_light;
      -webkit-transition: all 1s ease-out;
      -moz-transition: all 1s ease-out;
      -ms-transition: all 1s ease-out;
      -o-transition: all 1s ease-out;
      transition: all 1s ease-out;
    }

    .form-group {
      //overflow: hidden;
      position: relative;
      /*
        &:before {
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          left: -10px;
          top: -10px;
          //background-color: $color_blue_light;
          border: 10px solid transparent;
          //border-top-color: $color_blue_light;
          transform: rotate(135deg);
          z-index: 9;
        }
        */
    }

    .form-group.error {
      .input-group:after {
        background-color: $color_red;
      }

      input {
        border-bottom: 1px solid $color_red !important;

        &::placeholder {
          color: $color_red;
        }
      }

      label.steps {
        color: $color_red;
      }
    }

    .hidefield {
      position: absolute;
      z-index: -11111;
      opacity: 0;
    }

    .form-group.step2 .input-group:after {
      width: 0;
    }

    .form-group.step2.animate .input-group:after {
      width: 25%;
    }

    .form-group.step3 .input-group:after {
      width: 25%;
    }

    .form-group.step3.animate .input-group:after {
      width: 50%;
    }

    .form-group.step3.valid .input-group:after {
      width: 50%;
    }

    .form-group.step4 .input-group:after {
      width: 50%;
    }

    .form-group.step4.animate .input-group:after {
      width: 75%;
    }

    .form-group.step4.valid.animate .input-group:after {
      width: 100%;
    }

    .form-group.error .input-group {
      border-color: $color_orange;
    }

    ._form-thank-you {
      color: $color_green_light;
      font-size: 0.75em;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      font-weight: 700;
      display: none;
    }

    &.successfully-sent {
      ._form-thank-you {
        display: block;
      }
    }
  }

  &.GrayBackgroundColor,
  &.bg-gray {
    .subscription-form input,
    .subscription-form input:active,
    .subscription-form input:focus {
      background-color: $color_white !important;
    }

    .subscription-form .input-group button div {
      color: $color_white;
    }
  }

  &.bg-primary-light-color {
    .outer-wrapper > .container {
      padding-top: $padding-section;
      padding-bottom: $padding-section;
      //background: $color_blue_light url(../images/left-2-transparent.svg) 110% 160% no-repeat;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        border: 3em solid transparent;
        border-top-color: $color_white;
        left: -3.1em;
        top: -3.1em;
        transform: rotate(135deg);
        z-index: -1;
      }

      &:after {
        position: absolute;
        content: '';
        //border: 3em solid transparent;
        //border-top-color: $color_bg_gray;
        bottom: -3.1em;
        right: -3.1em;
        transform: rotate(-45deg);
        z-index: -1;
      }

      .subscription-form {
        input {
          background-color: $color_white !important;
        }
      }
    }
  }
  @media screen and (max-width: $media_md) {
    .subscription-form {
      margin-top: $padding_content_small;
    }

    &.bg-primary-light-color {
      .outer-wrapper > .container {
        -webkit-background-size: 50vmin;
        background-size: 50vmin;

        &:after {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: $media_xs) {
    //padding-top: 0 !important;
    .subscription-form {
      .form-group {
        overflow: visible;

        label {
          &.steps {
            left: 0;
            top: 115%;
            font-size: 14px;
            width: 30px;
            transform: none;
          }
        }

        .input-group {
          input {
            padding: 13px 20px;
          }

          .input-group-append {
            .steps {
              display: none !important;
            }
          }

          button {
            width: 70px;

            &:before {
              left: -20px;
              width: 145%;
            }

            i {
              padding-right: 0;
            }
          }
        }
      }
    }

    &.bg-primary-light-color {
      .outer-wrapper > .container {
        &:before {
          position: absolute;
          content: '';
          border: 2.0em solid transparent;
          border-top-color: $color_white;
          left: -2.1em;
          top: -2.1em;
          transform: rotate(135deg);
          z-index: -1;
        }

        &:after {
          position: absolute;
          content: '';
          border: 2em solid transparent;
          border-top-color: $color_bg_gray;
          bottom: -2.1em;
          right: -2.1em;
          transform: rotate(-45deg);
          z-index: -1;
        }
      }
    }
  }
}
/*==team*/
/*==latest-news*/
section.team-section {
  .heading-container {
    margin-bottom: 2.5em;
    justify-content: center;

    h1,
    h2,
    h3 {
      margin-bottom: 0;
    }

    .inner-content {
      position: relative;
      width: 100%;
      //hr{
      //  position: absolute;
      //  width: 100%;
      //  bottom: -24px;
      //  left: 0px;
      //  margin: 0 auto;
      //}
      .line {
        position: absolute;
        bottom: -15px;
        width: 100%;
        height: 1px;
        left: 0;
        border-top: 1px solid $color_line_black;
      }
      //&:after {
      //  content: '';
      //  position: absolute;
      //  bottom: -15px;
      //  width: 100%;
      //  height: 1px;
      //  left: 0;
      //  background-color: $color_line_black;
      //}
    }

    .right {
      display: inline-flex;
      margin-top: -10px;
    }
  }

  .placeholder-wrapper {
    position: relative;
    overflow: hidden;
  }

  .content-wrapper {
    position: relative;
    //background-color: $color_bg_gray;
    width: 100%;
    //&:before {
    //  content: '';
    //  position: absolute;
    //  right: -2em;
    //  bottom: -2em;
    //  border: 2em solid transparent;
    //  border-top-color: $color_white;
    //  transform: rotate(-45deg);
    //}
    .popup-video-play {
      position: relative;
    }

    .content {
      padding: 20px 20px 20px 0;

      .size-4 {
        text-transform: none;
        font-weight: $fw_medium;
        font-size: 14px;
      }

      .inner-content {
        margin: 15px 0 0;
        //color: $color_medium_black;
      }

      a {
        color: black;
        text-decoration: none;
        display: block;

        span {
          //color: $color_blue;
          font-weight: $fw_medium;
          padding-right: 5px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .team-info {
      //margin-top: -49px;
      position: absolute;
      left: 0;
      bottom: -2px;

      .btn-primary {
        padding: 15px 20px;
        font-size: 12px;
        justify-content: left;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:before {
          width: 140%;
          left: auto;
          right: -9px;
          min-width: auto;
          top: -2px;
        }
      }
    }

    .more {
      position: absolute;
      bottom: 20px;
      left: 20px;
      text-decoration: none;

      i {
        color: #fff !important;
        margin-left: 10px;
        font-size: 20px;
        opacity: 1;
      }
    }

    .image-placeholder {
      min-height: 330px;
      position: relative;
      overflow: hidden;

      i {
        font-size: 60px !important;
        color: #fff;
        opacity: 0.8;
        position: relative;
        z-index: 999;

        &:hover {
          opacity: 1;
        }
      }

      .inner-image {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          //background: linear-gradient(180deg, $color_blue 0%, $color_blue_dark 100%);
          width: 100%;
          height: 100%;
          opacity: 0;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }

        &.portrait {
          background-position: top center !important;
        }
      }

      &:hover {
        .inner-image {
          transform: scale(1.2);

          &:after {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .blog-grid-container {
    margin-bottom: -30px;

    .col-md-4 {
      margin-bottom: 30px;
    }
  }

  &.GrayBackgroundColor,
  &.bg-gray {
    .content-wrapper {
      &:before {}
    }
  }
  @media screen and (min-width: $media_md) {
    .heading-container {
      .btn-gray {
        width: 150px;
        margin-left: 10px;
      }

      h2,
      h3 {
        max-width: 60%;
      }
    }

    .tag-section {
      ul {
        margin-bottom: -10px;
      }
    }
  }
  @media screen and (min-height: $media_sm) {
    .heading-container {
      .flex-item {
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    .heading-container {
      .right {
        display: inline;
      }

      .btn {
        display: none;
      }
    }

    .sm-carousel {
      width: 100vw;

      .row {
        flex-flow: row nowrap;
        overflow-x: scroll;
        overflow-y: hidden;

        .content-wrapper {
          min-width: 60vmin;
        }
      }
    }
  }
  @media screen and (max-width: $media_sm) and (hover: hover) {
    .sm-carousel {
      width: 100vw;

      .row {
        /* width */
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: $color_light_black;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color_medium_black;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color_medium_black;
        }
      }
    }
  }
}
/*section==team-popup*/
#team-popup {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;

  .container {
    max-width: 979px;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $color_black;
    opacity: 0.9;
  }

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-wrap {
    margin-bottom: 20px;

    .item-title {
      margin-top: 2px;
      display: block;
    }
  }

  .detail {
    background-color: $color_white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .close {
    position: absolute;
    top: -35px;
    right: 0;
    color: $color_white;
    cursor: pointer;
    //font-size: 12px;
    text-transform: uppercase;
    font-size-adjust: none;
    opacity: 1;
    font-weight: normal;

    .material-icons {
      font-size: 20px;
      color: $color_white;
      font-weight: normal;
    }
  }

  .photo {
    padding-right: 0;
    display: flex;

    .image-placeholder {
      width: 100%;
      //height: 35vw;
      min-height: 350px;
    }
  }

  .social-links {
    margin-top: 20px;

    a {
      text-decoration: none;
      margin-right: 20px;

      i {
        background-color: transparent;
        //border: 1px solid $color_blue;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        //color: $color_blue;
      }
    }
  }

  .row {
    width: 100%;
  }
  @media screen and (max-width: $media_sm) {
    .close {
      right: 15px;
      top: -25px;
    }

    .photo {
      padding-left: 0;
      padding-right: 0;

      .image-placeholder {
        background-position: top center !important;
      }
    }
  }
  @media screen and (max-width: $media_sm), screen and (max-height: 650px) {
    .container {
      > .row {
        max-height: 80vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          background: $color_light_black;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $color_medium_black;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $color_medium_black;
        }
      }
    }
  }
}
/*==categorylist*/
section.category-list {
  padding-top: 15px;
  padding-bottom: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 15px 0 0;
    overflow-x: scroll;
    white-space: nowrap;

    li {
      display: inline-block;

      &:first-child {
        margin-left: 15px;
      }

      a {
        padding: 18px;
        text-decoration: none;
        text-transform: uppercase;
        //color: $color_blue_light;
        display: block;
      }

      &.active,
      &:hover {
        a {
          background-color: $color_bg_gray;
        }
      }
    }
  }
  @media screen and (max-width: $media_sm) and (hover: hover) {
    ul {
      /* width */
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: $color_light_black;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color_medium_black;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $color_medium_black;
      }
    }
  }
}
@import "modules/video-carousel.scss";
@import "modules/element-calculator.scss";
/*==footer*/
footer {
  //background-color: $color_bg_gray;
  padding: $padding_section 0 0;
  width: 100%;
  display: block;
  float: left;
  color: $color_white;

  a,
  h5 {
    color: $color_medium_black;
    text-decoration: none;

    &:hover {
      color: $color_medium_black !important;
      text-decoration: none;
    }
  }

  .size-3 {
    text-transform: uppercase;
    color: $color_blue_light;
  }

  .logo-section {
    a {
      line-height: 20px;
      font-size: 14px;

      &.address {
        line-height: 24px;
        margin-bottom: 5px;
        display: block;
      }
    }

    .phone {
      display: block;
      font-weight: $fw_medium;
      color: $color_blue;
      margin-top: 20px;
    }

    .email {
      display: block;
      font-weight: $fw_medium;
      color: $color_blue;
      margin-top: 20px;
    }

    .disclosure {
      margin-top: 20px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .footer-nav {
    padding: 0;
    list-style: none;

    a {
      display: block;
      text-decoration: none;
      line-height: 32px;
      font-size: 14px;
    }
  }

  a.facebook {
    display: block;
    margin-top: 15px;
    font-size: 14px;
    color: $color_medium_black;
    text-decoration: none;

    span {
      font-size: 14px;
      color: $color_medium_black;

      &:hover {
        color: #fff;
      }
    }
    padding-left: 0;

    i {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.1);
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      font-size: 14px;
      width: 40px;
      height: 40px;
      text-align: center;
      padding-top: 14px;
      margin-right: 10px;
    }
  }

  h6 {
    font-size: 16px;
    color: $color_white;
    display: block;
    font-weight: normal;
  }

  .copyright {
    font-size: 0.75em;
    font-weight: $fw_medium;
    line-height: 17px;
    color: $color_white;
    line-height: 50px;
  }

  .terms {
    //margin-top: calc(50px + 2vw);
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: $padding_extra_small;

    .social {
      padding: 0;
      margin-bottom: 0;
      list-style: none;

      li {
        display: inline-block;
        margin-right: 20px;

        a {
          border: 1px solid;
          width: 40px;
          height: 40px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 16px;
            position: relative;

            div {
              position: absolute;
              font-size: 7px;
              top: 6px;
              left: 5px;
            }
          }

          &:hover {
            color: $color_white !important;
          }
        }
      }
    }

    .powered * {
      font-size: 0.75em;
      font-weight: $fw_thin;
      line-height: 20px;
    }

    .powered {
      //color: $color_medium_black;
      a {
        text-decoration: none;
        font-weight: $fw_medium;

        &:hover {
          color: $color_white !important;
        }

        &.has-footer-logo {
          img {
            width: 50%;
          }
        }
      }

      &.mobile {
        .text-left,
        .text-right {
          //color: $color_medium_black;
        }
      }
    }
  }
  @media screen and (max-width: $media_sm) {
    padding: $padding_extra_small 0;

    .logo-section {
      margin-bottom: 40px;

      .social {
        margin-bottom: 0;
      }
    }

    .footer-nav {
      margin-bottom: 40px;
    }

    h6 {
      margin-top: 20px;
      text-align: center;
    }

    .terms {
      margin-top: 0;

      ul {
        li {
          a {
            padding: 0 10px;
          }
        }
      }

      .copyright {
        color: $color_light_black;
        font-size: 0.875em;
        font-weight: $fw_thin;
        line-height: 17px;
        float: left;

        br {
          display: none;
        }
      }

      .powered {
        margin-top: 15px;

        .has-footer-logo {
          margin-top: 15px;
        }
      }
    }
  }
}
/*==home*/
body.Home .follow-on-facebook {
  margin-top: 0;
}

body.ErrorPage,
body.Page {
  //header {
  //  background-color: $color_black;
  //}
  .main {
    padding: $padding_section 0;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      margin-bottom: $padding_content_laptop;
    }

    h2 {
      //font-size: 2.5em;
      font-weight: $fw_thin;
      font-size: 1.8em;
      margin-bottom: 1em;
    }

    h2 {
      //font-size: 2.5em;
      font-weight: $fw_thin;
      font-size: 1.5em;
      margin-bottom: 1em;
    }

    .content {
      font-size: $fz_20;

      em,
      li,
      p,
      span {
        line-height: 1.5;
        font-size: 16px;
      }

      a {
        color: $color_red;
      }

      h2 {
        margin-top: 1em;
      }

      h5 {
        margin-bottom: 0.5em;
      }

      b {
        color: $color_medium_black;
      }
    }

    .content-container {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  &.page-Security {
    .main .content-container {
      /*login page*/
      h1 {
        margin-bottom: 20px;
        font-size: 50px;
        //color: $color_blue;
      }

      .content {
        margin-bottom: 20px;
      }
      text-align: center;

      .btn-toolbar {
        justify-content: center;
        align-items: center;
      }

      .message {
        &.warning {
          display: none;
        }

        &.bad {
          color: $color_red;
        }
      }

      form {
        max-width: 350px;
        margin: 0 auto;

        .field {
          margin-bottom: 15px;
        }

        .checkbox {
          input {
            vertical-align: middle;
            visibility: hidden;
            cursor: pointer;
          }

          label {
            cursor: pointer;
            margin-bottom: unset;
            position: relative;
            padding-left: 5px;
            font-weight: normal !important;

            &:before {
              content: '';
              position: absolute;
              width: 14px;
              height: 14px;
              left: -16px;
              top: 0;
              //border: 1px solid $color_blue;
            }

            &:after {
              content: '';
              position: absolute;
              width: 10px;
              height: 5px;
              left: -14px;
              top: 3px;
              //border: 2px solid $color_blue;
              border-top: 0;
              border-right: 0;
              transform: rotate(-45deg);
              opacity: 0;
            }
          }

          input:checked + label {
            &:before {
              //background-color: $color_blue;
            }

            &:after {
              border-color: #fff;
              opacity: 1;
            }
          }
        }

        .text {
          padding: 10px;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          text-align: center;
          width: 100%;

          &:active,
          &:focus,
          &:hover {
            outline-style: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }

          label {
            font-weight: normal !important;

            span {
              font-weight: normal !important;
            }
          }
        }

        input.text {
          border: 1px solid $color_medium_black;
          padding: 15px 20px 13px;
          outline-style: none;
          border-radius: 0;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0;
          height: auto;
          color: $color_black !important;
        }

        .action {
          width: 100%;
          padding: 15px 10px 10px;
          //background-color: $color_blue;
          //border: 1px solid $color_blue;
          color: #fff;
          position: relative;
          z-index: 1;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;

          &:hover {
            //background-color: $color_blue_dark;
            outline-style: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }

        #ForgotPassword {
          display: block;
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }
  @media screen and (max-width: $media_md) {
    .main {
      padding: $padding_section 15px;
    }
  }
}

section.search-result-page {
  margin-bottom: $padding_section;

  .searchResults {
    article {
      padding-bottom: $padding_content;
      border-bottom: 1px solid $color_light_black;
      margin-top: $padding_content;
      color: $color_medium_black;

      a.readmore {
        color: $color_blue_light;
        display: block;
      }
    }
  }

  #PageNumbers {
    margin-top: $padding_content;

    a {
      color: $color_blue_light;
    }
  }
}
/*==animation */
/* Feel free to change duration  */
.animated {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-animated-out {
  z-index: 1;
}

.owl-animated-in {
  z-index: 0;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body {
  position: relative;
}
/*for */
@-webkit-keyframes slideInUpLong {
  0% {
    opacity: 0;
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUpLong {
  0% {
    opacity: 0;
    -webkit-transform: translateY(150px);
    -ms-transform: translateY(150px);
    transform: translateY(150px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.in-view-bottom.slideInUpLongB,
.in-view.slideInUpLongT,
.slideInUpLong.in-view {
  -webkit-animation-name: slideInUpLong;
  animation-name: slideInUpLong;
}
@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(75px);
    -ms-transform: translateY(75px);
    transform: translateY(75px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.in-view-bottom.slideInUpB,
.in-view.slideInUpT,
.slideInUp.in-view {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100vw);
    -ms-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100vw);
    -ms-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.in-view-bottom.slideInRightB,
.in-view.slideInRight,
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
@-webkit-keyframes scrollSide {
  0% {
    opacity: 0;
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(10px);
  }
}
/* Standard syntax */
@keyframes scrollSide {
  0% {
    opacity: 0;
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(10px);
  }
}

.delay-0-1 {
  animation-delay: 0.1s, 0.1s;
}

.delay-0-2 {
  animation-delay: 0.2s, 0.2s;
}

.delay-0-3 {
  animation-delay: 0.3s, 0.3s;
}

.delay-0-4 {
  animation-delay: 0.4s, 0.4s;
}

.delay-0-5 {
  animation-delay: 0.5s, 0.5s;
}

.delay-0-6 {
  animation-delay: 0.6s, 0.6s;
}

.delay-0-7 {
  animation-delay: 0.7s, 0.7s;
}

.delay-0-8 {
  animation-delay: 0.8s, 0.8s;
}

.delay-0-9 {
  animation-delay: 0.9s, 0.9s;
}

.delay-0-10,
.delay-1,
.delay-1full {
  animation-delay: 1s, 1s;
}

.animation-element:not(.default) {
  opacity: 0;
}

.animation-element.in-view-bottom,
.animation-element.in-view:not(.bottom) {
  opacity: 1;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

section.animation-element {
  > .overlay {
    &:before {
      position: absolute;
      content: '';
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $color_white;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -ms-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
    }
  }

  > .overlay.left-out {
    &:before {
      right: auto;
      left: 0;
    }
  }

  &.in-view {
    > .overlay {
      &:before {
        width: 0;
      }
    }
  }
}
/*==customisable*/
section {
  position: relative;

  &.padding-top {
    padding-top: $padding_section !important;
  }

  &.padding-bottom {
    padding-bottom: $padding_section !important;
  }

  &.margin-top {
    margin-top: $padding_section !important;
  }

  &.margin-top-half {
    margin-top: $padding_content !important;
  }

  &.margin-bottom {
    margin-bottom: $padding_section !important;
  }
}
/*==body-content-wrapper*/
div.body-content-wrapper {
  min-height: calc(100vh - 120px - 415px);

  &.chat-enabled {
    min-height: calc(100vh - 120px - 463px);
  }
}
//==@media
@media screen and (min-height: 600px) {
  body > .main,
  body.Blog .latest-news,
  body.Gallery .gallery-item {
    min-height: calc(100vh - 313px - 150px);
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1160px;

    &.container-wide {
      max-width: 1320px;
      padding: 0 15px;
    }

    &.container-small {
      max-width: 975px;
    }
  }

  .container-fluid {
    padding: 0 30px;
  }
}
@media screen and (min-width: $media_xl) {
  .container {
    padding: 0;
  }
}
@media screen and (max-width: $media_lg) {
  .container {
    max-width: 100%;
  }
}
@media screen and (max-width: $media_sm) {
  section {
    &.padding-top {
      padding-top: $padding_content;
    }

    &.padding-bottom {
      padding-bottom: $padding_content;
    }

    &.margin-top {
      margin-top: $padding_content;
    }

    &.margin-bottom {
      margin-bottom: $padding_content;
    }

    .bg-pattern {
      display: none !important;
    }
  }

  section.bg-gray,
  section.bg-primary-light-color {
    padding-top: $padding_extra_small;
    padding-bottom: $padding_extra_small;
  }
}
@media (min-width: 768px) {
  .col-md-2-5 {
    -ms-flex: 0 0 20.833%;
    flex: 0 0 20.833%;
    max-width: 20.833%;
  }
}
/*==ie*/
body.ie {
  &.Home {
    header nav.navbar {
      padding-top: 10px !important;
    }
  }

  header {
    nav .top {
      margin-bottom: 15px;
    }
  }

  section.gallery-item,
  section.latest-news {
    .content-wrapper .placeholder i {
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
    }
  }

  section.blog-single {
    .img-fluid {
      width: 100%;
    }
  }
}

.icon-scroll {
  position: relative;
  width: 18px;
  height: 30px;
  margin: 15px auto;
  box-shadow: inset 0 0 0 2px $color_white;
  border-radius: 25px;
}

.icon-scroll:before {
  position: absolute;
  left: 50%;
  content: '';
  width: 4px;
  height: 4px;
  background: $color_white;
  margin-left: -2px;
  top: 5px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}
/* Standard syntax */
@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(16px);
  }
}
@media screen and (max-width: $media_sm) {
  .icon-scroll {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.has-signup .home-inner.end {
  //margin-top: 20px;
  margin-top: 0;
}

header.is-hidden {
  -webkit-transform: translateY(-120px);
  -moz-transform: translateY(-120px);
  -ms-transform: translateY(-120px);
  transform: translateY(-120px);
}

header.is-hidden.has-signup {
  -webkit-transform: translateY(-180px);
  -moz-transform: translateY(-180px);
  -ms-transform: translateY(-180px);
  transform: translateY(-180px);
}

header {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: background 0.25s, transform 0.3s !important;
  -moz-transition: background 0.25s, transform 0.3s !important;
  -ms-transition: background 0.25s, transform 0.3s !important;
  transition: background 0.25s, transform 0.3s !important;
}
/*remove*/
.direction-bar,
.grecaptcha-badge,
.row.section-title {
  display: none !important;
}
/*==ThemeDoc */
body.ThemeDoc {
  div.documentation {
    section:not(:last-of-type) {
      padding-bottom: 0;
    }

    .item {
      font-weight: normal;
      margin-top: 20px;
      display: block;
      text-decoration: none;

      a {
        text-decoration: none;
      }

      img {
        margin-top: 10px;
        -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
      }
    }

    .popup-video-play {
      position: relative;
      left: 0;
      top: 0;
    }
  }
}
/*for external link*/
.nonvisual-indicator {
  display: none !important;
}
// image with form
@import "modules/image-with-form.scss";

.form-container span,
.form-container ul li,
.team-section a,
.team-section span {
  font-size: 16px;
}
