/*
list-block : normal list without image : second column visible
alternate-block : left right (text / image swap)
alternate-text-top: left right (text / image swap) with text starting top

note:
on full width : same as above only fluid with 0 padding
on bg white content bg is also white

*/

section.two-block-toggle {
  position: relative;
  .image-holder {
    min-height: 400px;
    height: 100%;
    width: 100%;
  }

  .size-3, h3 {
    display: block;
    text-transform: none;
    margin-bottom: 5px;
  }

  a.more {
    margin-top: 1.8em;
  }

  > .container > .row:not(:last-child) {
    margin-bottom: $padding_section_small;
    padding-bottom: $padding_section_small;
  }

  &[class*='no-cta'] {
    a.btn {
      display: none !important;
    }
  }

  &[class*='list-block'] {
    .align-items-center {
      background-color: transparent;
    }

    > .container > .row:not(:last-child) {
      padding-bottom: $padding_section_small;
      border-bottom: 1px solid $color_medium_black;
    }

    //> .container > .row:not(:last-child) {
    //  margin-bottom: $padding_section_small;
    //}
    .content {
      margin-top: 0 !important;
    }

    .image-container {
      position: relative;
      //display: inline-grid;

      .image-holder {
        margin-top: 10px;
        width: 45%;
        //position: absolute;
        min-height: auto;
        height: auto;
        left: 0;
        bottom: 0;
        align-self: flex-end;
        justify-content: center;

        img {
          width: 100%;
        }
      }
    }
  }

  &[class*='toggle-block'] {
    .content, p {
      color: $color_white;
    }
    .size-3, h3 {
      color: $color_white;
    }
    .align-items-center {
      //border: 1px solid $color_blue_dark;
      //background-color: $color_blue_dark;
    }
    .align-items-center {
      padding: $padding_content;
    }
  }

  @media screen and (min-width: $media_sm_) {
    &[class*='list-block'] {
      .image-container {
        .size-2{
          margin-bottom: 15px;
        }
      }
    }
    &.alternate-block.text-top {
      .align-items-center {
        //padding: 0 15px !important;
        align-items: flex-start !important;
      }

    }

    &:not(.list-block):not(.list-block-no-cta) {

      > .container > .row:nth-child(2n) {

        .col-md-6:first-child, .col-md-5:first-child {
          order: 2;
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 2;
        }
        .col-md-6:last-child, .col-md-5:last-child {
          order: 1;
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;

        }
        .col-md-5:last-child {
          margin-left: 8.333333%;
        }
      }
      > .container > .row:nth-child(2n-1) {

        .col-md-5:first-child {
          margin-left: 8.333333%;
        }
      }

      /*for right*/
      &.right {
        .col-md-5.align-items-center {
          margin-left: 8.333%;
          order: 1;
        }
        > .container > .row:nth-child(2n-1) {
          .col-md-5:first-child {
            margin-left: 0;
            order: 2;
          }
        }

        .col-md-6.align-items-center {
          //margin-left: 8.333%;
          padding-left: 0;
          order: 1;
        }
        > .container, .container-fluid > .row:nth-child(2n-1) {
          .col-md-6:first-child {
            margin-left: 0;
            order: 2;
          }
        }
      }

    }

  }

  @media screen and (max-width: $media_sm) {

    &[class*='list-block'] {

      .align-items-center {
        padding: 20px 0;
      }

      .image-container {
        position: relative;
        .image-holder {
          position: relative;
          margin-bottom: 20px;
          width: 100%;
          img {
            width: auto;
            min-width: 125px;
          }
        }
      }
      .size-2 {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      h3 {
        margin-bottom: 10px;
      }
    }

  }

  @media screen and (max-width: $media_xs) {
    .image-holder {
      min-height: 215px;
    }
    .bg-green.p-5 {
      padding: 15px !important;
    }
    > .container > .row:not(:last-child) {
      margin-bottom: $padding_content;
    }
    .align-items-center {
      padding: 20px;
    }


    p a[title="button"] {
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.875em;
      line-height: 1.14;

      padding: 18px 25px;
      height: 50px;
      min-width: 12.5em;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      color: $color_white !important;
      position: relative;
      z-index: 1;
      overflow: hidden;
      margin-bottom: 40px;
      &:before {
        position: absolute;
        content: '';
        left: -15px;
        top: -1px;
        width: 116%;
        height: 100%;
        background-color: $color_blue_light;
        transform: skewX(40deg);
        z-index: -1;
      }
      &:hover {
        text-decoration: none;
      }
    }

    .content-wrapper .content p:last-of-type a[title="button"] {
      margin-bottom: 0;
    }

  }

}

/*==alternate*/
section.two-block-toggle.alternate-block {
  .align-items-center {
    padding: 8.33% !important;
    .content-wrapper {
      max-width: 33.33vw;
    }
  }
  .image-holder {
    visibility: hidden;
    height: auto;
    padding-top: 100%;
  }
  .overlay-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
  }

  &:not(.bg-white):not(.bg-gray) {
    a.more, &:hover {
      &:before {
        background-color: $color_white;
      }
    }
  }

  &.right {
    .overlay-image {
      right: auto;
      left: 0;
    }
    //.align-items-center {
    //  padding-right: 8.33%;
    //}
  }

  .container {
    .overlay-image {
      max-width: 100%;
    }
  }

  @media screen and (min-width: $media_sm_){
    .container-fluid{
      padding: 0;
    }

  }
  @media screen and (max-width: $media_sm) {

    .align-items-center {
      padding: 50px 15px !important;
      .content-wrapper {
        max-width: 100%;
      }
    }
    &.bg-gray {
      .align-items-center {
        padding: 50px 15px 0 !important;
      }
    }
    &.bg-white {
      .align-items-center {
        padding: 30px 0px 0 !important;
      }
    }

    .overlay-image {
      display: none;
    }
    .image-holder {
      visibility: visible;
      padding-top: 0%;
    }
    &.bg-gray {
      padding-bottom: $padding_section_small !important;
    }
  }

}

section.two-block-blue {
  padding: $padding_section 0;
  .placeholder {
    margin-top: 30px;
    .overlay {
      padding-top: 66%;
      width: 100%;
    }
    .image-summary {
      margin-top: 15px;
      display: block;
      position: relative;
      padding-left: 75px;
      font-size: 12px;
      font-weight: $fw_medium;
      line-height: 18px;
      //color: $color_blue_light;

    }
  }

  &.py-0 {
    .placeholder {
      margin-top: 0;
    }
  }

  .col-md-7 {
    margin-top: $padding_section_small;
  }
  &.reverse-Yes {
    .col-md-5 {
      -ms-flex-order: 2;
      order: 2;
    }
    .col-md-7 {
      -ms-flex-order: 1;
      order: 1;
    }
  }
  @media screen and (max-width: $media_sm) {
    .placeholder {
      //margin-left: -15px;
      //margin-top: 0;
      //width: 80%;
      .overlay {
        max-width: 100%;
      }
    }
    .col-md-7 {
      margin-top: 15px;
      .placeholder {
        margin-left: auto;
        margin-right: -15px;
      }
    }
  }
}


/*when container layout and text top*/
/*==

alternate block text top  + bg-white is used for small thumb which content
if there no section  bg-white - content will have primary bg color so need patting top
*/

section.two-block-toggle.alternate-block.text-top.bg-white{
  .container {
    .col-md-6.justify-content-md-center {
      justify-content: left !important;
      padding-left: 0 !important;
    }
    .overlay-image {
      width: 75%;
      left: 0;
    }
    .image-holder {
      padding-top: 69%;
    }
  }

  &.right {
    .container {
      .overlay-image {
        left: auto;
        right: 0;
      }

    }
  }

  @media screen and (min-width: $media_sm_) {
    .justify-content-md-center{
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
}


/*if no bg-white but has bg,  and text top then container fluid needs to have full width
and content will have primary bg so need paddint
 */
section.two-block-toggle.alternate-block.text-top.has-bg:not(.bg-white){
  @media screen and (min-width: $media_sm_) {
    .container-fluid{
      padding: 0 !important;
    }
    @media screen and (min-width: $media_sm_) {
      .justify-content-md-center{
        padding-top: $padding_content !important;
        padding-bottom: $padding_content !important;
      }
    }
  }
}




/*
when there are multiple block with padding botom 0 in mobile give padding to content so it won touch next one

*/
@media screen and (max-width: $media_sm_){
  section.two-block-toggle.pb-0 + section.two-block-toggle.pt-0 > .container-fluid{
    margin-top: 50px !important;
  }
  section.two-block-toggle.pb-0 + section.two-block-toggle.pt-0 > .container{
    margin-top: 50px !important;
  }
}