section.video-carousel {
  .inner-content {
    margin-top: $padding_content_small;
    margin-bottom: 1.8em;
  }

  .item {
    width: 100%;
    padding-top: 90%;
    position: relative;
    margin-top: -11.5%;
    margin-bottom: -11.5%;
  }

  .btn-black {
    margin-top: $padding_section;
    float: left;
  }

  #video-nav {
    transform: translateY(-100%);
    width: 50%;
    //background-color: $color_white;
    position: relative;
    z-index: 1;
  }

  img.overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100% !important;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }

  .custom-owl-btn {
    .owl-next,
    .owl-prev {
      &:after {
        top: 32%;
      }
    }
  }

  .popup-video-play {
    position: absolute;
    margin: 10px auto;
    width: 80px;
    height: 80px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;

    .material-icons {
      font-size: 24px;
      opacity: 1;
    }
  }
  @media screen and (max-width: $media_xl) {
    .item {
      padding-top: 90%;
    }
  }
  @media screen and (max-width: $media_md) {
    .item {
      padding-top: 80%;
    }
  }
  @media screen and (max-width: $media_sm) {
    #video-nav {
      margin-left: 0;
    }
  }
}